<template>
  <div class="dashboard">
    <Navigation></Navigation>
    <div class="dashboard__inner">
      <slot></slot>
    </div>
  </div>
</template>
<script>
import Navigation from "@/components/Navigation.vue";
import { mapActions } from "vuex";
export default {
  components: { Navigation },
  async created() {
    await this.apiGetUserInfo();
  },
  methods: {
    ...mapActions("user", ["apiGetUserInfo"]),
  },
};
</script>
<style lang="scss">
.dashboard {
  width: 100vw;
  height: 100vh;
  display: flex;
  background: #fbfaff;
  overflow: hidden;
  &__inner {
    position: relative;
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;
    padding: 7rem 5.2rem 5.85rem 4rem;
    overflow-y: auto;
    overflow-x: hidden;
    z-index: 4;
  }
}
</style>