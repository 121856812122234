<template>
  <transition name="menu-nav__fade">
    <div class="menu-nav" v-if="menuActive">
      <div class="header">
        <router-link to="/" class="logo_link">
          <img
            class="logo"
            v-webp="require('@/assets/images/TGHLogoWhite.png')"
            src="@/assets/images/TGHLogoWhite.png"
            alt="logo"
          />
        </router-link>
        <div class="menu">
          <IconButton
            class="menu__button menu__button_search button--lg"
            @click="$emit('showSearch')"
          >
            <IconSearch></IconSearch>
          </IconButton>
          <IconButton class="menu__button button--lg" @click="$emit('hidenMenu')">
            <IconCloseBold></IconCloseBold>
          </IconButton>
        </div>
      </div>
      <div class="menu-nav__inner">
        <div class="menu-nav__container">
          <div
            class="menu-nav__item"
            v-for="(item, index) in menu"
            :key="`category-${index}`"
            :class="{
              'menu-nav__item--hidden': !item.active,
              'menu-nav__item--underline': index === 2,
            }"
            @click.self="categoryActive(index)"
          >
            <div class="menu-nav__title" @click.self="categoryActive(index)">
              <router-link class="menu-nav__title-text" :to="item.link">
                {{ item.name }}
              </router-link>
              <IconArrowTop
                class="menu-nav__title-arrow"
                :class="{ 'menu-nav__title-arrow--hidden': !item.active }"
                width="10"
                height="16"
                color="#fff"
                @click.native="categoryActive(index)"
              ></IconArrowTop>
            </div>
            <div class="menu-nav__item-inner">
              <router-link
                class="menu-nav__item-link"
                :class="{
                  'menu-nav__item-link--bold': link.bold,
                }"
                v-for="(link, index) in item.links"
                :key="`link-${index}`"
                :to="link.link"
              >
                {{ link.title }}
              </router-link>
            </div>
          </div>
        </div>
      </div>
      <!-- <Subscribe></Subscribe> -->
      <Footer :footer="true"></Footer>
    </div>
  </transition>
</template>

<script>
import IconButton from "@/components/buttons/IconButton.vue";
import IconSearch from "@/components/icons/IconSearch.vue";
import Footer from "@/components/Footer.vue";
import Subscribe from "@/components/Subscribe.vue";
import IconArrowTop from "@/components/icons/IconArrowTop.vue";
import IconCloseBold from "@/components/icons/IconCloseBold.vue";

/* import IconMenu from "@/components/icons/IconMenu.vue"; */

import { mapGetters, mapActions } from "vuex";

export default {
  components: {
    IconButton,
    IconSearch,
    /* IconMenu, */
    Footer,
    Subscribe,
    IconArrowTop,
    IconCloseBold,
  },
  props: {
    menuActive: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      menu: [
        {
          active: false,
          name: "The Grottas",
          link: "/about",
          links: [
            {
              title: "Sandy & Lou",
              link: "/about",
            },
            {
              title: "House History",
              link: "/about/the-history",
            },
            {
              title: "Interview",
              link: "/interview",
            },
            {
              title: "Videos",
              link: "/library/videos",
            },
          ],
        },
        {
          active: false,
          name: "Home",
          link: "/",
          links: [
            {
              title: "Outside",
              link: "/exteriors/1",
            },
            {
              title: "Inside",
              link: "/interiors/1",
            },
            // {
            //   title: "Collection",
            //   link: "/art/collection",
            // },
          ],
        },
        {
          active: false,
          name: "Collection",
          link: "/art/collection",
          links: [
            { title: "Collection Highlights", link: "/art/collection", bold: true },
            {
              title: "Creators",
              link: "/creators",
            },
            {
              title: "Collection loading...",
              link: "",
            },
          ],
        },
        {
          active: false,
          name: "Library",
          link: "/library",
          links: [
            {
              title: "Books",
              link: "/library/books",
            },
            {
              title: "Publications",
              link: "/library/publications",
            },
            // {
            //   title: "Blog",
            //   link: "/library/blog",
            // },
            // {
            //   title: "Book: The Grotta Home",
            //   link: "/library/the-grotta-home-by-richard-meier",
            // },
            // {
            //   title: "Book: The Grotta House",
            //   link: "/library/the-grotta-house-by-richard-meier",
            // },
          ],
        },
      ],
    };
  },
  computed: {
    ...mapGetters("publicCategories", {
      categories: "getMenuCategoriesList",
      // media: "getMenuMediaList",
    }),
  },
  async created() {
    await this.apiGetCategoriesList();
    this.menu[2].links = [
      { title: "Collection Highlights", link: "/art/collection", bold: true },
      {
        title: "Creators",
        link: "/creators",
      },
    ];
    // this.media.forEach(mediaC => {
    //   this.menu[2].links.push({
    //     title: mediaC.name,
    //     link: `/art/${mediaC.slug}`,
    //   });
    // });
    //this.menu[2].links = [];
    this.categories.forEach(category => {
      this.menu[2].links.push({
        title: category.name,
        link: `/art/${category.slug}`,
      });
    });
    this.menu[2].links.push({
      title: "All Categories",
      link: `/categories`,
    });
  },
  watch: {
    "$route.path": function () {
      this.menu.map(item => {
        item.active = false;
      });
    },
  },
  methods: {
    ...mapActions("publicCategories", ["apiGetCategoriesList"]),
    categoryActive(index) {
      this.menu[index].active = !this.menu[index].active;
    },
  },
};
</script>

<style lang="scss" scoped>
.menu-nav {
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: center;
  left: 0;
  top: 0;
  height: 100vh;
  width: 100vw;
  z-index: 1000;
  background-color: $lightBlack;
  @media (max-width: $md) {
    overflow-y: auto;
    justify-content: flex-start;
    -webkit-overflow-scrolling: touch;
  }
  &__fade {
    &-enter-active,
    &-leave-active {
      transition: all 1s ease;
    }
    &-enter {
      transform: translateX(100%);
      &-to {
        transform: translateX(0%);
      }
    }
    &-leave {
      transform: translateX(0%);
      &-to {
        transform: translateX(100%);
      }
    }
  }
  &__inner {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5rem 0;
    @media (max-width: $xxs) {
      align-items: flex-start;
      height: max-content;
    }
  }
  &__container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;
    padding: 0 5rem;
    @media only screen and (max-width: $xs) {
      padding: 0 25px;
    }
  }
  &__item {
    display: flex;
    flex-direction: column;
    padding-bottom: 3rem;
    width: max-content;
    //max-height: 40rem;
    //max-height: 35rem;
    transition: all 0.5s linear;
    //margin-right: 4rem;
    margin-right: 9rem;
    &:last-of-type {
      margin-right: 0;
    }
    @media only screen and (max-width: $md) {
      width: 50%;
      margin: 0;
      padding: 0 4rem 3rem;
      &:last-of-type {
        margin: 0;
      }
    }
    @media (max-width: $xxs) {
      width: 100%;
      margin-right: 0;
      border-bottom: 1px solid $grey;
    }
    @media only screen and (max-width: $xs) {
      padding: 0 20px 3rem;
    }
    @media only screen and (max-width: $xxxxs) {
      padding: 0 0 3rem;
    }

    &--hidden {
      @media (max-width: $xs) {
        max-height: 10.2rem;
      }
    }
    &-inner {
      display: flex;
      flex-direction: column;
      padding: 2.5rem 5px 5px;
      height: 100%;
      overflow: hidden;
    }
    &-link {
      font-family: $fontOpenSans;
      font-size: 1.6rem;
      font-weight: 400;
      line-height: 1.875;
      letter-spacing: -0.27px;
      color: $white;
      text-decoration: none;
      cursor: pointer;
      text-transform: capitalize;
      &--bold{
        font-weight: 900;
      }
    }
    &--underline {
      .menu-nav__item-link:last-of-type {
        text-decoration: underline;
      }
    }
  }
  &__title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 3rem 5px 0;
    &-text {
      font-family: $fontBodoni;
      font-size: 3.8rem;
      font-weight: 500;
      line-height: 1.26;
      letter-spacing: -0.63px;
      color: $white;
      text-decoration: none;
      @media only screen and (max-width: $xs) {
        font-size: 3.4rem;
      }
    }
    &-arrow {
      width: auto;
      height: auto;
      cursor: pointer;
      transition: all 0.5s linear;
      transform: rotate(180deg);
      &--hidden {
        transform: rotate(0);
      }
      @media (min-width: $xs) {
        display: none;
      }
    }
  }
}
.header {
  position: relative;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  padding: 50px 50px 20px 50px;
  transition: all 0.3s ease;
  @media (max-width: $xxs) {
    justify-content: flex-end;
    padding: 40px 25px 15px 25px;
  }
}
.logo {
  height: 20rem;
  max-width: none;
  z-index: 0;
  &_link {
    position: absolute;
    display: block;
    top: 50%;
    left: 50%;
    margin-top: 30px;
    transform: translate(-50%, -50%);
  }
}
.menu {
  z-index: 1;
  display: flex;
  &__button {
    border: none;
    border-radius: 50%;
    background-color: $white;
    height: 7rem;
    width: 7rem;
    &_search {
      @media (max-width: $xxs) {
        display: none;
      }
    }
    &:hover {
      background-color: rgba($color: $light2Grey, $alpha: 1);
    }
    @media (max-width: $xxs) {
      height: 6rem;
      width: 6rem;
    }
  }
  .button:first-of-type {
    margin-right: 4rem;
    @media (max-width: $xxs) {
      margin-right: 1.5rem;
    }
  }
}

@keyframes showCategory {
  from {
  }
  to {
  }
}
</style>

