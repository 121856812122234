import Vue from "vue";
import router from "@/router";
export default {
  namespaced: true,
  state: {
    loading: false,
    access: {
      access_token: null,
      expires_in: null,
    },
  },
  getters: {
    isAuthenticated(state) {
      return state.access;
    },
    getLoading(state) {
      return state.loading;
    },
  },
  mutations: {
    setToken(state, { access_token, expires_in }) {
      Vue.$cookies.set("token", access_token, expires_in);
      state.token = { access_token, expires_in };
    },
    changeLoader(state, payload) {
      state.loading = payload;
    },
  },
  actions: {
    async loginUser({ commit }, payload) {
      commit("changeLoader", true);
      let result = {
        success: false,
        error: null,
      };
      await Vue.axios
        .post("/api/login", payload)
        .then(res => res.data)
        .then(data => {
          result.success = true;
          commit("setToken", data);
          router.push("/admin");
        })
        .catch(e => {
          result = { error: "" };
          if (e.response.status === 400) {
            result.error = e.response.data.detail.message;
          }
          if (e.response.status === 422) {
            result.error = e.response.data.detail.password
              ? e.response.data.detail.password[0]
              : "Some Error";
          }
        })
        .finally(() => {
          commit("changeLoader", false);
        });
      return result;
    },
    async logoutUser({ commit }) {
      await Vue.axios
        .post("/api/logout")
        .then(res => res.data)
        .then(data => {
          commit("setToken", {
            access_token: null,
            expires_in: null,
          });
          router.push("/login");
        });
    },
    async forgotPassword({ commit }, payload) {
      let result = false;
      await Vue.axios
        .post("/api/public/forgot-password", payload)
        .then(res => res.data)
        .then(data => {
          result = true;
          Vue.toasted.show(data.message, {
            duration: 3000,
            type: "success",
          });
          commit("setToken", {
            access_token: null,
            expires_in: null,
          });
        })
        .catch(e => {
          result = { error: "" };
          if (e.response.status === 400) {
            result.error = e.response.data.detail.message;
          }
          if (e.response.status === 422) {
            result.error = e.response.data.detail.email
              ? e.response.data.detail.email[0]
              : "Some Error";
          }
        });
      return result;
    },
    async resetPassword({ commit }, payload) {
      let result = false;
      await Vue.axios
        .post("/api/public/reset-password", payload)
        .then(res => res.data)
        .then(data => {
          result = data.status === "success";
          Vue.toasted.show(data.message, {
            duration: 3000,
            type: data.status,
          });
          commit("setToken", {
            access_token: null,
            expires_in: null,
          });
        })
        .catch(e => {
          result = { error: "" };
          if (e.response.status === 400) {
            result.error = e.response.data.detail.message;
          }
          if (e.response.status === 422) {
            if (e.response.data?.detail?.password)
              result.error = e.response.data.detail.password[0];
            if (e.response.data?.message) {
              Vue.toasted.show(e.response.data.message, {
                duration: 3000,
                type: "error",
              });
              router.push("/login");
              result.error = e.response.data.message;
            }
          }
        });
      return result;
    },
  },
};
