import Vue from 'vue'
export default {
    namespaced: true,
    state: {
        books: [],
        loading: false
    },
    getters: {
        getBooks(state) { return state.books },
        getLoading(state) { return state.loading }
    },
    mutations: {
        setBooks(state, payload) { state.books = payload },
        setLoading(state, payload) { state.loading = payload }
    },
    actions: {
        async apiGetBooks({ commit }, { search = '', excludeIdx }) {
            let blogsString = '';
            let excludeIdxString = '';
            if (excludeIdx.length > 0) {
                excludeIdxString = excludeIdx.map(el => {
                    return el.id;
                }).join(",");
            }
            let success = false;
            commit('setLoading', true)
            await Vue.axios.get(`/api/admin/books?search=${search}&exclude_ids=${excludeIdxString}`)
                .then((res) => res.data)
                .then((data) => {
                    success = true
                    commit('setBooks', data.response)
                })
                .finally(() => {
                    commit('setLoading', false)
                })
            return success
        },
    }
}