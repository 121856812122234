<template>
  <div
    class="form-control"
    :class="{
      'form-control--icon': icon,
      'form-control--invalid': validateError,
      'form-control--icon': toggle,
    }"
  >
    <div class="form-control_relative">
      <input
        required
        :type="type"
        class="form-control__input"
        v-model="item"
        :disabled="disabled"
        :placeholder="placeholder"
      />
      <label class="form-control__label"
        >{{ placeholder }}
        <span class="form-control__label_required" v-if="required">*</span></label
      >
      <span v-if="icon || toggle" class="form-control__icon">
        <slot name="icon"></slot>
      </span>
    </div>
    <slot name="text"></slot>
    <p class="form-control__error">{{ validateError }}</p>
  </div>
</template>
<script>
export default {
  props: {
    element: { type: [String, Number] },
    placeholder: { type: String },
    type: { type: String, default: "text" },
    icon: { type: Boolean, default: false },
    toggle: { type: Boolean, default: false },
    required: { type: Boolean, default: false },
    validateError: { type: String, default: "" },
    disabled: { type: Boolean, default: false },
  },
  data() {
    return {
      item: "",
    };
  },
  created() {
    this.item = this.element;
  },
  watch: {
    element(newVal, oldVal) {
      if (newVal != oldVal) {
        this.item = this.element;
      }
    },
    item(newVal, oldVal) {
      if (newVal != oldVal) {
        this.$emit("input", newVal);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.form-control {
  position: relative;
  margin-top: 10px;
  &_relative {
    position: relative;
  }
  &__icon {
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
  }
  &__label {
    width: calc(100% - 5.1rem);
    position: absolute;
    @include type($fw: 500);
    color: $lightBlack;
    top: 50%;
    left: 2.4rem;
    transform: translateY(-50%);
    padding: 1px 1.1rem;
    background-color: $white;
    transition: all 0.1s ease;
    pointer-events: none;
    @media (max-height: 499px) and (max-width: 899px), (max-width: $sm) {
      font-size: 14px;
    }
    &_required {
      font-size: 18px;
      font-weight: 600;
      color: $error;
      @media (max-height: 499px) and (max-width: 899px), (max-width: $sm) {
        font-size: 14px;
      }
    }
  }
  &__input {
    height: 9rem;
    padding: 0 2.4rem 0 2.7rem;
    border: 0.5px solid $lightGrey;
    border-radius: 12px;
    @include type();
    color: $lightBlack;
    &:-webkit-autofill {
      background-color: rgba(255, 255, 255, 1) !important;
      background-image: none !important;
      -webkit-box-shadow: 0 0 0px 10em white inset !important;
      -webkit-text-fill-color: $black !important;
    }
    &::placeholder {
      color: $white;
      opacity: 0;
    }
    &:-ms-input-placeholder {
      color: $white;
      opacity: 0;
    }
    &::-ms-input-placeholder {
      color: $white;
      opacity: 0;
    }
    @media (max-height: 499px) and (max-width: 899px), (max-width: $sm) {
      height: 7rem;
      font-size: 14px;
    }
  }
  &__input:focus ~ &__label,
  &__input:valid ~ &__label,
  &__input:not(:placeholder-shown) ~ &__label {
    top: 0;
    width: auto;
  }
  &__simple-text {
    display: inline-block;
    padding: 7px 0 0;
    @include type($fs: 13px, $fw: 600);
    //color: $greyDark;
    color: $green;
  }
  &__error {
    position: absolute;
    @include type($fs: 12px, $fw: 500);
    color: $error;
    padding-top: 3px;
    width: max-content;
  }
  &--icon {
    .form-control__input {
      padding-right: 7rem;
    }
    .form-control__label {
      width: calc(100% - 9.7rem);
    }
  }
  &--invalid {
    .form-control__input {
      border-color: $error;
    }
  }
  &--sm {
    .form-control__input {
      height: 7.1rem;
    }
  }
  &--grey {
    .form-control__label {
      background-color: #fbfaff;
    }
  }
  &--xs {
    width: 100%;
    .form-control__input {
      height: 6rem;
      border-radius: 8px;
      padding: 0 20px 0 10px;
      @media (max-height: 499px) and (max-width: 899px), (max-width: $sm) {
        height: 5rem;
        font-size: 14px;
      }
    }
    .form-control__label {
      left: 10px;
    }
  }
}
</style>

