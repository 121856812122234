const ViewingRoom = () => import("@/views/viewingRoom/ViewingRoom.vue");
const ViewingRoomArtwork = () => import("@/views/viewingRoom/ViewingRoomArtwork.vue");

export default [
  {
    path: "/viewing-room/:viewingRoomSlug",
    name: "ViewingRoom",
    component: ViewingRoom,
    meta: {
      layout: "base",
      footer: true,
    },
  },
  {
    path: "/viewing-room/:viewingRoomSlug/:artworkSlug",
    name: "ViewingRoomArtwork",
    component: ViewingRoomArtwork,
    meta: {
      layout: "base",
      footer: true,
    },
  },
];
