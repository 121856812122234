<template>
  <div class="page">
    <!-- <BaseButton
      v-if="isAdditionalButtonVisible"
      class="additional-menu-button button--white button--ellipse button--lg"
      tabindex="1"
      text="Table of Contents"
      @click="menuActive = !menuActive"
    /> -->
    <header
      class="header"
      :class="{
        'header--background': scrollPosition > 200,
        header_breadcrumbs: breadcrumbs.length > 0,
      }"
    >
      <div class="breadcrumbs" :class="{ 'breadcrumbs--black': breadcrumbsBlack }">
        <template v-for="(item, index) in breadcrumbs">
          <router-link
            v-if="index === 0"
            class="breadcrumbs__link"
            :key="`breadcrumb-${index}`"
            :to="item.to"
          >
            <IconHome class="breadcrumbs__home"></IconHome>
          </router-link>
          <template v-else>
            <IconArrowTop
              class="breadcrumbs__arrow"
              :key="`arrow-${index}`"
              width="11"
              height="7"
              color="#ffffff"
            >
            </IconArrowTop>
            <router-link class="breadcrumbs__link" :key="`breadcrumb-${index}`" :to="item.to">
              {{ item.title }}
            </router-link>
          </template>
        </template>
      </div>
      <div class="menu">
        <IconButton
          class="menu__button button--menu"
          text="Search"
          @click="searchActive = !searchActive"
        >
          <IconSearch width="17" height="18" />
        </IconButton>
        <IconButton
          class="menu__button button--menu"
          :text="isAdditionalButtonVisible"
          @click="menuActive = !menuActive"
        >
          <IconMenu width="22" height="8" />
        </IconButton>
      </div>
    </header>
    <slot></slot>
    <Footer :footer="footer" />
    <MenuNavigation
      :menuActive="menuActive"
      @hidenMenu="menuActive = !menuActive"
      @showSearch="searchActive = !searchActive"
    />
    <Search :searchActive="searchActive" @hidenSearch="hidenSearch" />
  </div>
</template>
<script>
import BaseButton from "@/components/buttons/BaseButton.vue";
import IconButton from "@/components/buttons/IconButton.vue";
import IconSearch from "@/components/icons/IconSearch.vue";
import IconMenu from "@/components/icons/IconMenu.vue";
import IconArrowTop from "@/components/icons/IconArrowTop.vue";
import IconHome from "@/components/icons/IconHome.vue";
import Footer from "@/components/Footer.vue";
import MenuNavigation from "@/components/menu/MenuNavigation.vue";
import Search from "@/components/menu/Search.vue";

import { mapGetters, mapMutations } from "vuex";

export default {
  components: {
    BaseButton,
    IconButton,
    IconSearch,
    IconMenu,
    IconArrowTop,
    IconHome,
    Footer,
    MenuNavigation,
    Search,
  },
  data() {
    return {
      scrollPosition: null,
      menuActive: false,
      searchActive: false,
    };
  },
  computed: {
    ...mapGetters({
      breadcrumbs: "getBreadcrumbs",
    }),
    footer() {
      return this.$route.meta.footer ?? false;
    },
    breadcrumbsBlack() {
      return this.$route.meta.breadcrumbsBlack ?? false;
    },
    isAdditionalButtonVisible() {
      if (this.$route.path === "/") return "Table of Contents";
      return "Menu";
    },
  },
  created() {
    const { search, search_tag } = this.$route.query;
    if (search || search_tag) this.searchActive = true;
  },
  mounted() {
    window.addEventListener("scroll", this.updateScroll);
  },
  watch: {
    "$route.path": function () {
      this.setBreadcrumbs([]);
      this.menuActive = false;
      this.searchActive = false;
      window.scroll(0, 0);
    },
    "$route.query.search_tag": function () {
      if (this.$route.query?.search_tag) this.searchActive = true;
      else this.searchActive = false;
    },
    "$route.query.search": function () {
      if (this.$route.query?.search) this.searchActive = true;
      else this.searchActive = false;
    },
  },
  methods: {
    ...mapMutations(["setBreadcrumbs"]),
    updateScroll() {
      this.scrollPosition = window.scrollY;
    },
    hidenSearch() {
      this.$router.push({ path: "", query: {} });
      this.searchActive = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.page {
  background-color: $lightBlack;
  min-height: 100vh;
  min-width: 100vw;
  display: flex;
  flex-direction: column;
}
.additional-menu-button {
  position: absolute;
  top: 62px;
  left: 50px;
  height: 3.6rem;
  width: 19rem;
  font-size: 1.6rem;
  z-index: 1;
  @media (max-width: $xs) {
    display: none;
  }
}
.header {
  position: fixed;
  z-index: 999;
  display: flex;
  justify-content: space-between;
  width: max-content;
  top: 0;
  right: 0;
  padding: 50px 50px 20px 50px;
  transition: all 0.3s ease;
  &_breadcrumbs {
    width: 100%;
  }
  @media (max-width: $xxs) {
    justify-content: flex-end;
  }
  @media (max-width: $sm) {
    padding: 40px 25px 15px 25px;
  }
  &--background {
    padding: 20px 50px;
    background-color: rgba($color: $black, $alpha: 0.3);
    .breadcrumbs--black {
      .breadcrumbs {
        &__link {
          color: $white;
        }
        &__home ::v-deep {
          & > path {
            fill: $white;
          }
        }
        &__arrow ::v-deep {
          & > g {
            fill: $white;
          }
        }
      }
    }
    @media (max-width: $xxs) {
      padding: 20px 25px 15px 25px;
    }
  }
}
.breadcrumbs {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  flex-grow: 1;
  &--black {
    .breadcrumbs {
      &__link {
        color: $black;
      }
      &__home ::v-deep {
        & > path {
          fill: $black;
        }
      }
      &__arrow ::v-deep {
        & > g {
          fill: $black;
        }
      }
    }
  }
  @media (max-width: $xxs) {
    display: none;
  }
  &__link {
    color: $white;
    font-family: $fontOpenSans;
    font-weight: 600;
    font-size: 1.8rem;
    line-height: 2.45;
    text-decoration: none;
    text-transform: uppercase;
    margin: 0 20px;
    max-width: 36rem;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    line-clamp: 1;
    text-overflow: ellipsis;
    overflow: hidden;
    &:first-of-type {
      margin: 0 20px 0 0;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    @media (max-width: $xxs) {
      font-size: 1.4rem;
      line-height: 3.14;
      margin: 0 15px;
    }
  }
  &__home {
    width: auto;
    height: auto;
  }
  &__arrow {
    width: auto;
    height: auto;
    transform: rotate(90deg);
  }
}
.menu {
  display: flex;
  &__button {
  }
  .button:first-of-type {
    margin-right: 10px;
    @media (max-width: $xxs) {
    }
  }
}
</style>

