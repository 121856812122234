import Vue from "vue";
export default {
  namespaced: true,
  state: {
    loading: false,
    tags: [],
    tagsHeaders: [
      {
        key: "title",
        title: "Tag",
        sortable: true,
      },
      {
        key: "indent",
        className: "indent",
      },
      {
        key: "edit",
        title: "Edit",
        sortable: false,
        className: "xsm",
        variables: "icon",
        icon: "IconEdit",
      },
      {
        key: "delete",
        title: "Delete",
        sortable: false,
        className: "xsm",
        variables: "icon",
        icon: "IconDelete",
      },
    ],
    matchedTags: [],
    tag: null,
    changesLoading: false,
    hasMorePages: false,
    page: 1,
    serverError: null,
  },
  getters: {
    getLoading(state) {
      return state.loading;
    },
    getTags(state) {
      return state.tags;
    },
    getTagsHeaders(state) {
      return state.tagsHeaders;
    },
    getMatchedTags(state) {
      return state.matchedTags;
    },
    getTag(state) {
      return state.tag;
    },
    getChangesLoading(state) {
      return state.changesLoading;
    },
    getHasMorePages(state) {
      return state.hasMorePages;
    },
    getServerError(state) {
      return state.serverError;
    },
  },
  mutations: {
    setLoading(state, payload) {
      state.loading = payload;
    },
    setTags(state, payload) {
      let tags = payload;
      if (state.page > 1) {
        state.tags = state.tags.concat(tags);
      } else {
        state.tags = tags;
      }
    },
    setMatchedTags(state, payload) {
      state.matchedTags = payload;
    },
    initialTag(state) {
      state.tag = null;
    },
    setTag(state, payload) {
      state.tag = payload;
    },
    setChangesLoading(state, payload) {
      state.changesLoading = payload;
    },
    setPage(state, payload) {
      state.page = payload;
    },
    setHasMorePages(state, payload) {
      state.hasMorePages = payload;
    },
    setError(state, payload) {
      state.serverError = payload;
    },
  },
  actions: {
    async apiGetTags({ commit }, { sort = "title", order = "asc", page = "1" }) {
      let success = false;
      commit("setLoading", true);
      commit("setHasMorePages", false);
      await Vue.axios
        .get(`/api/admin/tags?sort=${sort}&order=${order}&page=${page}`)
        .then(res => res.data)
        .then(data => {
          success = true;
          commit("setPage", page);
          commit("setHasMorePages", data.hasMorePages);
          commit("setTags", data.response);
        })
        .finally(() => {
          commit("setLoading", false);
        });
      return success;
    },
    async apiGetMatchedTags({ commit }, { search, excludeIdx }) {
      let limit = "all";
      commit("setLoading", true);

      let excludeIdxString = "";
      if (search != "" && excludeIdx.length > 0) {
        excludeIdxString = excludeIdx
          .map(el => {
            return el.id;
          })
          .join(",");
      }

      if (search === "") {
        commit("setMatchedTags", []);
        commit("setLoading", false);
      } else {
        await Vue.axios
          .get(`/api/admin/tags?search=${search}&exclude_ids=${excludeIdxString}&limit=${limit}`)
          .then(res => res.data)
          .then(data => {
            commit("setMatchedTags", data.response);
          })
          .finally(() => {
            commit("setLoading", false);
          });
      }
    },
    async apiPostTag({ commit }, payload) {
      commit("setChangesLoading", true);
      let result = {
        success: false,
        tag: null,
        error: null,
      };
      await Vue.axios
        .post("/api/admin/tag", payload)
        .then(res => res.data)
        .then(data => {
          result.success = true;
          result.tag = data.response.tag;
        })
        .catch(e => {
          if (e.response.status === 422) {
            if (e.response.data?.detail?.title[0]) {
              result.error = "The tag has already been taken";
              commit("setError", "The tag has already been taken");
            }
          }
        })
        .finally(() => {
          commit("setChangesLoading", false);
        });
      return result;
    },
    async apiGetTag({ commit }, id) {
      let result = false;
      await Vue.axios
        .get(`/api/admin/tag/${id}`)
        .then(res => res.data)
        .then(data => {
          result = true;
          commit("setTag", data.response);
        });
      return result;
    },
    async apiPutTag({ commit }, payload) {
      commit("setChangesLoading", true);
      let result = false;
      await Vue.axios
        .put(`/api/admin/tag/${payload.id}`, payload)
        .then(res => res.data)
        .then(data => {
          result = true;
          commit("setError", null);
        })
        .catch(e => {
          let response = e.response;
          if (response.status === 422) {
            if (response.data?.detail?.title[0]) {
              commit("setError", "The tag has already been taken");
            }
          }
        })
        .finally(() => {
          commit("setChangesLoading", false);
        });
      return result;
    },
    async apiDeleteTag({ commit }, id) {
      let result = { success: false, message: null };
      await Vue.axios
        .delete(`/api/admin/tag/${id}`)
        .then(res => res.data)
        .then(data => {
          result.success = true;
          result.message = data.message;
        });
      return result;
    },
  },
};
