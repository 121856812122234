<template>
  <transition name="search__fade">
    <div class="search" v-if="searchActive">
      <div class="header">
        <div class="menu">
          <IconButton class="menu__button button--lg" @click="$emit('hidenSearch')">
            <IconCloseBold></IconCloseBold>
          </IconButton>
        </div>
      </div>
      <div class="search__inner" :class="{ 'search__inner--search-active': search }">
        <h2 class="search__title text-title">Search</h2>
        <div class="search__input-container">
          <BaseInput
            class="search__input"
            :element="search"
            placeholder="Search"
            @input="inputSearch"
          ></BaseInput>
          <IconSearch class="search__icon" color="#fff"></IconSearch>
        </div>
        <p class="search__mesage text-description" v-if="loading">Searching...</p>
        <p class="search__mesage text-description" v-if="!loading && search && searchResult.result">
          {{ `${searchResult.count} Results` }}
        </p>
        <template v-if="!loading && search">
          <div class="search__result-nav">
            <template v-for="(item, index) in nav">
              <BaseButton
                v-if="item.count"
                :key="`nav-${index}`"
                class="search__result-nav-button"
                :class="{ 'button--white': item.active, 'button--outline-white': !item.active }"
                :text="`${item.text} (${item.count})`"
                @click="clickNav(item.text)"
              ></BaseButton>
            </template>
          </div>
          <div class="search__result">
            <template v-if="nav[0].active">
              <p
                class="search__mesage text-description"
                v-if="!loading && search && searchResult.artworks.list.length === 0"
              >
                No Results
              </p>
              <router-link
                v-for="(item, key) in searchResult.artworks.list"
                :key="`artwork-${key}`"
                :to="{ path: `/art/${item.category_slug}/${item.slug}` }"
                class="search__result-item"
              >
                <div class="artwork">
                  <div class="image-box">
                    <img class="image" :src="item.search_image" />
                    <div class="hover-container text-sub-title">Click to View</div>
                  </div>
                  <h3 class="title text-sub-title">{{ item.title }}</h3>
                  <span class="author text-description">{{ item.author }}</span>
                </div>
              </router-link>
            </template>
            <template v-else-if="nav[1].active">
              <p
                class="search__mesage text-description"
                v-if="!loading && search && searchResult.artists.list.length === 0"
              >
                No Results
              </p>
              <router-link
                v-for="(item, key) in searchResult.artists.list"
                :key="`artist-${key}`"
                :to="{ path: `/creators/${item.slug}` }"
                class="search__result-item"
              >
                <div class="artist">
                  <div class="image-box">
                    <img class="image" v-if="item.search_image" :src="item.search_image" />
                    <div class="non-image" v-else>
                      <p class="non-image-text text-sub-title">No Image</p>
                    </div>
                    <div class="hover-container text-sub-title">Click to View</div>
                  </div>
                  <h3 class="name text-sub-title">{{ item.full_name }}</h3>
                </div>
              </router-link>
            </template>
            <template v-else-if="nav[2].active">
              <p
                class="search__mesage text-description"
                v-if="!loading && search && searchResult.categories.list.length === 0"
              >
                No Results
              </p>
              <router-link
                v-for="(item, key) in searchResult.categories.list"
                :key="`blog-${key}`"
                :to="{ path: `/art/${item.slug}` }"
                class="search__result-item"
              >
                <div class="blog">
                  <div class="image-box">
                    <img class="image" :src="item.image" />
                    <div class="hover-container text-sub-title">Click to View</div>
                  </div>
                  <h3 class="title text-sub-title">{{ item.name }}</h3>
                  <!-- <span class="author text-description">{{ item.author }}</span> -->
                </div>
              </router-link>
            </template>
            <template v-else-if="nav[3].active">
              <p
                class="search__mesage text-description"
                v-if="!loading && search && searchResult.publications.list.length === 0"
              >
                No Results
              </p>
              <router-link
                v-for="(item, key) in searchResult.publications.list"
                :key="`editorial-${key}`"
                :to="{ path: `/library/publications/${item.slug}` }"
                class="search__result-item"
              >
                <div class="editorial">
                  <h3 class="title text-sub-title">{{ item.title }}</h3>
                  <span class="author text-description">{{ item.author }}</span>
                  <p class="description text-description">{{ item.description }}</p>
                  <BaseButton
                    class="button--white button--lg button--ellipse"
                    text="Read More"
                    @click="$router.push({ path: `/library/publications/${item.slug}` })"
                  ></BaseButton>
                </div>
              </router-link>
            </template>
          </div>
          <div class="search__buttons-box">
            <BaseButton
              v-if="hasMorePages"
              class="search__button button--white button--lg button--ellipse"
              text="Load More"
              @click="loadMore"
            />
          </div>
        </template>
      </div>
      <Footer :footer="true"></Footer>
    </div>
  </transition>
</template>

<script>
import BaseInput from "@/components/inputs/BaseInput.vue";
import BaseButton from "@/components/buttons/BaseButton.vue";
import IconButton from "@/components/buttons/IconButton.vue";
import IconSearch from "@/components/icons/IconSearch.vue";
import IconMenu from "@/components/icons/IconMenu.vue";
import IconCloseBold from "@/components/icons/IconCloseBold.vue";
import Footer from "@/components/Footer.vue";
import { mapGetters, mapMutations, mapActions } from "vuex";

export default {
  components: {
    BaseInput,
    BaseButton,
    IconButton,
    IconSearch,
    IconMenu,
    IconCloseBold,
    Footer,
  },
  props: {
    searchActive: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      search: "",
      loading: false,
      activeNav: {},
      nav: [
        {
          active: true,
          text: "ART",
          key: "artworks",
          page: 1,
          count: 0,
        },
        {
          active: false,
          text: "CREATORS",
          key: "artists",
          page: 1,
          count: 0,
        },
        // {
        //   active: false,
        //   text: "BLOG",
        //   key: "blogs",
        //   page: 1,
        //   count: 0,
        // },
        {
          active: false,
          text: "CATEGORIES",
          key: "categories",
          page: 1,
          count: 0,
        },
        {
          active: false,
          text: "PUBLICATIONS",
          key: "publications",
          page: 1,
          count: 0,
        },
      ],
    };
  },
  computed: {
    ...mapGetters("publicSearch", {
      searchResult: "getSearchResultList",
      tags: "getTags",
    }),
    hasMorePages() {
      return this.searchResult[this.activeNav.key]?.hasMorePages ?? false;
    },
  },
  async created() {
    await this.apiGetTags();
    const { search, search_tag } = this.$route.query;
    if (search) {
      this.search = search ?? "";
      await this.apiGetSearchList({ search: this.search });
    }
    if (search_tag) {
      this.search = "tag:" + (search_tag ?? "");
      await this.searchTags();
    }
  },
  watch: {
    searchActive() {
      this.search = "";
      this.apiGetTags();
      this.setSearchResultList({
        data: {
          categories: { count: 0, list: [] },
          artists: { count: 0, list: [] },
          artworks: { count: 0, list: [] },
          publications: { count: 0, list: [] },
        },
      });
    },
    "$route.query.search": function () {
      this.search = this.$route.query.search ?? "";
    },
    "$route.query.search_tag": function () {
      this.search = "tag:" + (this.$route.query.search_tag ?? "");
    },
  },
  methods: {
    ...mapActions("publicSearch", ["apiGetSearchList", "apiGetTags", "apiGetSearchTags"]),
    ...mapMutations("publicSearch", ["setSearchResultList"]),
    async searchTags({ key = "", page = "" }) {
      const searchTag = this.search.split("tag:")[1].trim();
      let tagsIds = [];
      this.tags.map(tag => {
        if (tag.title === searchTag) tagsIds.push(tag.id);
      });
      await this.apiGetSearchTags({ tags: tagsIds.join(","), key, page });
    },
    inputSearch(val) {
      this.loading = true;
      this.search = val;
      clearTimeout(this.timer);
      this.timer = setTimeout(async () => {
        if (this.search.includes("tag:")) await this.searchTags({});
        else await this.apiGetSearchList({ search: this.search });
        this.nav.map(item => {
          item.count = this.searchResult[item.key].count;
          item.active = false;
        });
        for (const item of this.nav) {
          if (item.count > 0) {
            this.activeNav = item;
            item.active = true;
            break;
          }
        }

        this.loading = false;
      }, 200);
    },
    clickNav(textButton) {
      this.nav.map(item => {
        if (item.text === textButton) {
          this.activeNav = item;
          item.active = true;
        } else item.active = false;
        item.page = 1;
      });
    },
    async loadMore() {
      this.busy = true;
      this.activeNav.page++;
      if (this.search.includes("tag:")) {
        await this.searchTags({ key: this.activeNav.key, page: this.activeNav.page });
      } else {
        await this.apiGetSearchList({
          search: this.search,
          key: this.activeNav.key,
          page: this.activeNav.page,
        });
      }

      this.nav.map(item => {
        item.count = this.searchResult[item.key].count;
      });
      this.busy = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.search {
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  left: 0;
  top: 0;
  height: 100vh;
  width: 100vw;
  overflow: auto;
  z-index: 1000;
  background-color: $lightBlack;

  &__fade {
    &-enter-active,
    &-leave-active {
      transition: all 1s ease;
    }

    &-enter {
      transform: translateX(100%);

      &-to {
        transform: translateX(0%);
      }
    }

    &-leave {
      transform: translateX(0%);

      &-to {
        transform: translateX(100%);
      }
    }
  }

  &__title {
    text-align: center;
    margin-bottom: 6rem;
    overflow: hidden;
    transition: all 0.5s ease;
  }

  &__input {
    width: 100%;
    max-width: none;
    margin: 0;
    flex-grow: 1;

    &::v-deep {
      .form-control__input {
        height: 4.4rem;
        padding: 0 10px;
        font-family: $fontOpenSans;
        font-weight: 700;
        font-size: 2.2rem;
        line-height: 1.23;
        color: $white;
        border: none;
      }

      .form-control__label {
        font-family: $fontOpenSans;
        font-size: 2.2rem;
        font-weight: 400;
        color: $white;
        opacity: 0.7;
        background: none;
        padding: 0;
        left: 1rem;
      }

      .form-control__input:focus ~ .form-control__label,
      .form-control__input:not(:placeholder-shown) ~ .form-control__label {
        display: none;
      }
    }

    &-container {
      display: flex;
      padding: 2rem 1.5rem 1rem 0.5rem;
      border-bottom: 1px solid $white;
      width: 100%;
      max-width: 66rem;
    }
  }

  &__inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 7rem 10.5rem 5rem;
    flex-grow: 1;
    transition: all 0.5s ease;

    @media (max-width: $xxs) {
      padding: 16rem 25px 5rem;
    }

    &--search-active {
      padding-top: 0;

      .search__title {
        height: 0;
        margin-bottom: 0;
      }

      @media (max-width: $xxs) {
        padding-top: 2.35rem;
      }
    }
  }

  &__icon {
    width: auto;
    height: auto;
  }

  &__mesage {
    padding: 15px 0 0;
    width: 100%;
    max-width: 66rem;
  }

  &__result {
    width: 100%;
    padding-top: 6rem;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 2rem;
    grid-row-gap: 5rem;

    @media screen and (max-width: $md) {
      grid-template-columns: 1fr 1fr;
    }

    @media screen and (max-width: $xs) {
      grid-template-columns: 1fr;
      grid-row-gap: 7rem;
    }

    &-nav {
      padding-top: 4.8rem;
      display: flex;

      @media screen and (max-width: $xs) {
        padding-top: 4.8rem;
        flex-wrap: wrap;
      }

      &-button {
        border-radius: 100px;
        height: 4.7rem;
        padding: 0 20px;
        margin-right: 10px;
        font-family: $fontOpenSans;
        font-weight: 600;
        font-size: 1.7rem;
        line-height: 1.35;
        letter-spacing: -0.02em;
        width: max-content;

        @media screen and (max-width: $xs) {
          margin-bottom: 10px;
        }

        &:last-of-type {
          margin-right: 0;
        }
      }
    }

    &-item {
      display: block;
      text-decoration: none;

      & > .artwork {
        display: flex;
        flex-direction: column;
        cursor: pointer;

        &:hover {
          .image-box {
            .hover-container {
              opacity: 1;
            }
          }
        }

        .image-box {
          position: relative;

          .image {
            width: 100%;
            height: 30vw;

            @media screen and (max-width: $md) {
              height: 40vw;
            }

            @media screen and (max-width: $xs) {
              height: 39rem;
            }
          }

          .hover-container {
            position: absolute;
            width: 100%;
            height: 100%;
            font-size: 3rem;
            line-height: 5rem;
            background: linear-gradient(transparent, 35%, rgba(0, 0, 0, 0.9));
            display: flex;
            justify-content: center;
            align-items: center;
            transition: all 0.3s ease;
            opacity: 0;
            top: 0;
          }
        }

        .title {
          font-size: 2.4rem;
          line-height: 3.6rem;
          margin-top: 1rem;
        }

        .author {
          margin-top: 0.5rem;
          font-size: 1.6rem;
          line-height: 2.5rem;

          @media screen and (max-width: $xs) {
            margin-top: 1rem;
            font-size: 1.6rem;
            line-height: 2.8rem;
          }
        }
      }

      & > .artist {
        display: flex;
        flex-direction: column;
        cursor: pointer;

        &:hover {
          .image-box {
            .hover-container {
              opacity: 1;
            }
          }
        }

        .image-box {
          position: relative;

          .image {
            width: 100%;
            height: 30vw;

            @media screen and (max-width: $md) {
              height: 40vw;
            }

            @media screen and (max-width: $xs) {
              height: 39rem;
            }
          }
          .non-image {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 30vw;
            background-color: rgba($color: $greyDark, $alpha: 1);
            @media screen and (max-width: $md) {
              height: 40vw;
            }
            @media screen and (max-width: $xs) {
              height: 39rem;
            }
            &-text {
            }
          }

          .hover-container {
            position: absolute;
            width: 100%;
            height: 100%;
            font-size: 3rem;
            line-height: 5rem;
            background: linear-gradient(transparent, 35%, rgba(0, 0, 0, 0.9));
            display: flex;
            justify-content: center;
            align-items: center;
            transition: all 0.3s ease;
            opacity: 0;
            top: 0;
          }
        }

        .name {
          font-size: 2.4rem;
          line-height: 3.1rem;
          margin-top: 1.2rem;
        }
      }

      & > .blog {
        display: flex;
        flex-direction: column;
        cursor: pointer;

        &:hover {
          .image-box {
            .hover-container {
              opacity: 1;
            }
          }
        }

        .image-box {
          position: relative;

          .image {
            width: 100%;
            height: 30vw;

            @media screen and (max-width: $md) {
              height: 40vw;
            }

            @media screen and (max-width: $xs) {
              height: 39rem;
            }
          }

          .hover-container {
            position: absolute;
            width: 100%;
            height: 100%;
            font-size: 3rem;
            line-height: 5rem;
            background: linear-gradient(transparent, 35%, rgba(0, 0, 0, 0.9));
            display: flex;
            justify-content: center;
            align-items: center;
            transition: all 0.3s ease;
            opacity: 0;
            top: 0;
          }
        }

        .title {
          font-size: 2.4rem;
          line-height: 3.6rem;
          margin-top: 1rem;
        }

        .author {
          margin-top: 0.5rem;
          font-size: 1.6rem;
          line-height: 2.5rem;

          @media screen and (max-width: $xs) {
            margin-top: 1rem;
            font-size: 1.6rem;
            line-height: 2.8rem;
          }
        }
      }

      & > .editorial {
        display: flex;
        flex-direction: column;
        cursor: pointer;

        .title {
          font-size: 2.8rem;
          line-height: 3.6rem;
          margin-top: 1rem;
        }

        .author {
          margin-top: 0.5rem;
          font-size: 1.6rem;
          line-height: 2.5rem;
          color: $light3Grey;

          @media screen and (max-width: $xs) {
            margin-top: 1rem;
            font-size: 1.6rem;
            line-height: 2.8rem;
          }
        }

        .description {
          margin-top: 1.5rem;
          font-size: 1.8rem;
          line-height: 2.7rem;
        }

        &::v-deep .button {
          margin-top: 2.5rem;
          max-width: 22rem;
          height: 5rem;
        }
      }
    }
    &-tags {
      width: 100%;
      padding: 9rem 16.5rem 0;
      display: flex;
      flex-direction: column;
      @media (max-width: $xxs) {
        padding: 9rem 25px 0;
        align-items: center;
      }
      &-item {
        text-decoration: none;
        font-size: 2.1rem;
      }
    }
  }

  &__buttons-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 5rem 0rem 7rem;
    width: 100%;
  }

  &__button {
    width: max-content;
    min-width: 22rem;
  }

  .header {
    position: relative;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    padding: 50px 50px 20px 50px;
    transition: all 0.3s ease;

    @media (max-width: $xxs) {
      justify-content: flex-end;
      padding: 40px 25px 15px 25px;
    }
  }

  .menu {
    display: flex;

    &__button {
      border: none;
      border-radius: 50%;
      background-color: $white;
      height: 7rem;
      width: 7rem;

      &_search {
        @media (max-width: $xxs) {
          display: none;
        }
      }

      &:hover {
        background-color: rgba($color: $light2Grey, $alpha: 1);
      }

      @media (max-width: $xxs) {
        height: 6rem;
        width: 6rem;
      }
    }
  }
}
</style>

