<template>
  <svg :width="width" :height="height" :viewBox="viewBox">
    <path
      :fill="color"
      fill-rule="evenodd"
      d="M24.40750122 12.56726074l.00167847.00167847c.78812408.78971863.78778839 2.07391357-.00117493 2.86312866-.38289642.38285828-.89152527.59350586-1.43237305.59350586h-.33170318v6.60409546C22.64392853 23.93669129 21.580513 25 20.27319336 25h-3.51137543c-.35603714 0-.6445961-.28869629-.6445961-.64453125v-5.17773438c0-.59635925-.4851265-1.08143615-1.08154678-1.08143615h-2.07109833c-.59642029 0-1.08171463.4850769-1.08171463 1.08143615v5.17773438c0 .35583496-.28855514.64453125-.6445961.64453125H7.72689438c-1.30715561 0-2.37073898-1.06330872-2.37073898-2.37033081v-6.60409546h-.35754776c-.02098465 0-.04179764-.00100708-.06261445-.00302124-.50845718-.02198791-.98384857-.2304535-1.34391403-.59065247-.78812027-.78804016-.78946304-2.06988525-.00386047-2.85993957.0028534-.00302124.00604248-.00621033.00906372-.00923157l8.9704628-8.96939087C12.9504776 3.21064758 13.45910263 3 14.00012589 3c.54102706 0 1.0496521.21064758 1.43221282.59333801l8.97516251 8.97392273zm-1.4318695 2.16925049c.19656371 0 .38154601-.07670593.52070617-.21585083.28637695-.28651428.2868805-.75262451.00134277-1.04014587-.00134277-.00134278-.00285339-.00268555-.00419616-.00402832l-8.97281266-8.97174073c-.13916015-.1391449-.32397842-.21568298-.52054595-.21568298-.19673539 0-.38155365.07653809-.52054596.21568298L4.5047531 13.47833252l-.00083923.00083923c-.28704834.28718567-.28704834.75430298 0 1.0413208.13496017.13511658.31507873.21131897.50711441.2146759.01023865.00016785.02014542.0006714.03021622.00134278h.95950699c.35604095 0 .6445961.28852844.6445961.64453125v7.2486267c0 .59619142.48529434 1.08126832 1.08154678 1.08126832h2.86677551v-4.53320313c0-1.30702209 1.06358338-2.37049865 2.37090683-2.37049865h2.07109833c1.30732345 0 2.3707428 1.06347656 2.3707428 2.37049865v4.53320313h2.8667755c.5964203 0 1.08154298-.4850769 1.08154298-1.08126831v-7.24862671c0-.3560028.28855896-.64453125.64459228-.64453125h.9763031z"
    />
  </svg>
</template>
<script>
export default {
  props: {
    viewBox: { type: String, default: "0 0 28 28" },
    width: { type: String, default: "28" },
    height: { type: String, default: "28" },
    color: { type: String, default: "#fff" },
  },
};
</script>
