import Vue from "vue";
export default {
  namespaced: true,
  state: {
    loading: false,
    artworks: [],
    artworksHeaders: [
      {
        key: "title",
        title: "Title",
        sortable: true,
      },
      {
        key: "artist_name",
        title: "Creator",
        sortable: true,
        className: "lg",
      },
      {
        key: "categories",
        title: "Category",
        sortable: true,
        className: "md",
        variables: "tag",
      },
      {
        key: "tags",
        title: "Tags",
        sortable: true,
        className: "md",
        variables: "tag",
      },
      {
        key: "status",
        title: "Status",
        sortable: true,
        className: "md",
        variables: "status",
      },
      {
        key: "date_added",
        title: "Date Added",
        sortable: true,
        className: "md",
      },
      {
        key: "edit",
        title: "Edit",
        sortable: false,
        className: "xsm",
        variables: "icon",
        icon: "IconEdit",
      },
      {
        key: "delete",
        title: "Delete",
        sortable: false,
        className: "xsm",
        variables: "icon",
        icon: "IconDelete",
      },
    ],
    art: null,
    changesLoading: false,
    hasMorePages: false,
    page: 1,
    serverError: {
      slug: null,
    },
    matchedArtworks: [],
    collection: [],
  },
  getters: {
    getLoading(state) {
      return state.loading;
    },
    getArtworksHeaders(state) {
      return state.artworksHeaders;
    },
    getArtworks(state) {
      return state.artworks;
    },
    getArt(state) {
      return state.art;
    },
    getChangesLoading(state) {
      return state.changesLoading;
    },
    getHasMorePages(state) {
      return state.hasMorePages;
    },
    getServerError(state) {
      return state.serverError;
    },
    getMatchedArtworks(state) {
      return state.matchedArtworks;
    },
    getCollection(state) {
      return state.collection;
    },
  },
  mutations: {
    setLoading(state, payload) {
      state.loading = payload;
    },
    setArtworks(state, payload) {
      let artworks = [];
      artworks = payload.map(el => {
        let tags = {
            main: "",
            additional: [],
          },
          categories = {
            main: "",
            additional: [],
          };
        if (el.tags.length > 0) {
          tags.main = el.tags[0].title;
          tags.additional = el.tags.slice(1);
        }
        if (el.categories.length > 0) {
          el.categories.map(category => {
            category.title = category.name;
          });
          categories.main = el.categories[0].title;
          categories.additional = el.categories.slice(1);
        }
        return {
          ...el,
          tags: tags,
          categories: categories,
          artist_name: el.artist_name ? el.artist_name : "—",
        };
      });
      if (state.page > 1) {
        state.artworks = state.artworks.concat(artworks);
      } else {
        state.artworks = artworks;
      }
    },
    initialArt(state) {
      state.art = null;
    },
    setArt(state, payload) {
      if (payload.categories.length > 0) {
        payload.categories.map(category => {
          category.title = category.name;
        });
      }
      state.art = {
        ...payload,
        artist: payload.artist != null ? payload.artist : { title: "", id: null },
      };
    },
    setChangesLoading(state, payload) {
      state.changesLoading = payload;
    },
    setPage(state, payload) {
      state.page = payload;
    },
    setHasMorePages(state, payload) {
      state.hasMorePages = payload;
    },
    setError(state, payload) {
      let serverError = state.serverError;
      if (payload != null) {
        for (let i in payload) {
          serverError[i] = payload[i][0];
        }
        state.serverError = serverError;
      } else
        state.serverError = {
          slug: null,
        };
    },
    setMatchedArtworks(state, payload) {
      state.matchedArtworks = payload;
    },
    setCollection(state, payload) {
      payload.forEach(art => {
        art.order = art.collection_order;
      });
      state.collection = payload;
    },
  },
  actions: {
    async apiGetArtworks(
      { commit },
      {
        status = "",
        categories = "",
        tags = "",
        sort = "title",
        order = "asc",
        page = "1",
        search = "",
        search_type = "",
      }
    ) {
      let success = false;
      commit("setLoading", true);
      commit("setHasMorePages", false);
      let query = {
        status,
        categories,
        tags,
        sort,
        order,
        search,
        search_type,
        page,
      };
      let queryStr = "";
      for (let key in query) {
        if (query[key] != "") {
          queryStr += `${key}=${query[key]}&`;
        }
      }
      await Vue.axios
        .get(`/api/admin/artworks?${queryStr}`)
        .then(res => res.data)
        .then(data => {
          success = true;
          commit("setPage", page);
          commit("setHasMorePages", data.hasMorePages);
          commit("setArtworks", data.response);
        })
        .finally(() => {
          commit("setLoading", false);
        });
      return success;
    },
    async apiPostArt({ commit }, payload) {
      let result = false;
      let data = {
        ...payload,
        artist_id: payload.artist.id,
        status: payload.status != null ? payload.status : "0",
      };
      commit("setChangesLoading", true);
      await Vue.axios
        .post("/api/admin/artwork", data)
        .then(res => res.data)
        .then(data => {
          result = true;
          commit("setError", null);
        })
        .catch(e => {
          let response = e.response;
          if (response.status === 422) {
            commit("setError", response.data.detail);
          }
        })
        .finally(() => {
          commit("setChangesLoading", false);
        });
      return result;
    },
    async apiGetArt({ commit }, id) {
      let result = false;
      await Vue.axios
        .get(`/api/admin/artwork/${id}`)
        .then(res => res.data)
        .then(data => {
          result = true;
          commit("setArt", data.response);
        });
      return result;
    },
    async apiPutArt({ commit }, payload) {
      commit("setChangesLoading", true);
      let result = false;
      let data = {
        ...payload,
        artist_id: payload.artist.id,
      };
      await Vue.axios
        .put(`/api/admin/artwork/${payload.id}`, data)
        .then(res => res.data)
        .then(data => {
          result = true;
          commit("setError", null);
        })
        .catch(e => {
          let response = e.response;
          if (response.status === 422) {
            commit("setError", response.data.detail);
          }
        })
        .finally(() => {
          commit("setChangesLoading", false);
        });
      return result;
    },
    async apiDeleteArt({ commit }, id) {
      let result = { success: false, message: null };
      await Vue.axios
        .delete(`/api/admin/artwork/${id}`)
        .then(res => res.data)
        .then(data => {
          result.success = true;
          result.message = data.message;
        });
      return result;
    },
    async apiPutChoseOrder({ commit }, payload) {
      let list = [...payload];
      let result = false;
      commit("setChangesLoading", true);
      await Vue.axios
        .put(`/api/admin/artworks-order`, { list })
        .then(res => res.data)
        .then(data => {
          result = true;
          //commit('setPage', 1)
          //commit('setHasMorePages', data.response.hasMorePages)
          //commit('setItems', data.response.data)
          Vue.toasted.show(data.message, {
            duration: 2000,
            type: "success",
          });
        })
        .finally(() => {
          commit("setChangesLoading", false);
        });
      return result;
    },
    async apiGetMatchedArtworks({ commit }, { search, excludeIdx }) {
      commit("setChangesLoading", true);
      let excludeIdxString = "";
      if (excludeIdx.length > 0) {
        excludeIdxString = excludeIdx
          .map(el => {
            return el.id;
          })
          .join(",");
      }

      await Vue.axios
        .get(`/api/admin/artworks-search?search=${search}&exclude_ids=${excludeIdxString}`)
        .then(res => res.data)
        .then(data => {
          commit("setMatchedArtworks", data.response);
        })
        .finally(() => {
          commit("setChangesLoading", false);
        });
    },
    async apiGetCollection({ commit }) {
      commit("setChangesLoading", true);
      await Vue.axios
        .get(`/api/admin/collection/list`)
        .then(res => res.data)
        .then(data => {
          commit("setCollection", data.response);
        })
        .finally(() => {
          commit("setChangesLoading", false);
        });
    },
    async apiPutCollectionOrder({ commit }, payload) {
      let list = [...payload];
      let result = false;
      commit("setChangesLoading", true);
      await Vue.axios
        .put(`/api/admin/collection/reorder`, { list })
        .then(res => res.data)
        .then(data => {
          result = true;
          //commit('setPage', 1)
          //commit('setHasMorePages', data.response.hasMorePages)
          //commit('setItems', data.response.data)
          Vue.toasted.show(data.message, {
            duration: 2000,
            type: "success",
          });
        })
        .finally(() => {
          commit("setChangesLoading", false);
        });
      return result;
    },
  },
};

