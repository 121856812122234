import Vue from "vue";
export default {
  namespaced: true,
  state: {
    loading: false,
    viewingRooms: [],
    viewingRoomsHeaders: [
      {
        key: "name",
        title: "Room Name",
        sortable: true,
        className: "capitalize",
      },
      {
        key: "artworks_count",
        title: "Included Pieces",
        sortable: true,
        className: "md",
      },
      {
        key: "date_created",
        title: "Date Created",
        sortable: true,
        className: "md",
      },
      {
        key: "indent",
        className: "indent",
      },
      {
        key: "view",
        title: "View",
        sortable: false,
        className: "xsm",
        variables: "icon",
        icon: "IconEye",
      },
      {
        key: "edit",
        title: "Edit",
        sortable: false,
        className: "xsm",
        variables: "icon",
        icon: "IconEdit",
      },
      {
        key: "delete",
        title: "Delete",
        sortable: false,
        className: "xsm",
        variables: "icon",
        icon: "IconDelete",
      },
    ],
    // searchedViewingRooms: [],
    viewingRoom: null,
    changesLoading: false,
    hasMorePages: false,
    page: 1,
    serverError: {
      slug: null,
    },
    quickSearchArtworks: [],
  },
  getters: {
    getLoading(state) {
      return state.loading;
    },
    getViewingRoomsHeaders(state) {
      return state.viewingRoomsHeaders;
    },
    getViewingRooms(state) {
      return state.viewingRooms;
    },
    // getSearchedViewingRooms(state) {
    //   return state.searchedViewingRooms;
    // },
    getViewingRoom(state) {
      return state.viewingRoom;
    },
    getChangesLoading(state) {
      return state.changesLoading;
    },
    getHasMorePages(state) {
      return state.hasMorePages;
    },
    getServerError(state) {
      return state.serverError;
    },
    getQuickSearchArtworks(state) {
      return state.quickSearchArtworks;
    },
  },
  mutations: {
    setLoading(state, payload) {
      state.loading = payload;
    },
    setViewingRooms(state, payload) {
      let viewingRooms = payload.map(el => {
        return {
          ...el,
          date_created: el.date_created
            ? Vue.moment(el.date_created, "YYYY-MM-DD").format("MMM DD YYYY")
            : "—",
        };
      });
      if (state.page > 1) {
        state.viewingRooms = state.viewingRooms.concat(viewingRooms);
      } else {
        state.viewingRooms = viewingRooms;
      }
    },
    setSearchedViewingRooms(state, payload) {
      state.searchedViewingRooms = payload;
    },
    initialViewingRoom(state) {
      state.viewingRoom = null;
    },
    setViewingRoom(state, payload) {
      state.viewingRoom = payload;
    },
    setChangesLoading(state, payload) {
      state.changesLoading = payload;
    },
    setPage(state, payload) {
      state.page = payload;
    },
    setHasMorePages(state, payload) {
      state.hasMorePages = payload;
    },
    setError(state, payload) {
      let serverError = state.serverError;
      if (payload != null) {
        for (let i in payload) {
          serverError[i] = payload[i][0];
        }
        state.serverError = serverError;
      } else
        state.serverError = {
          slug: null,
        };
    },
    setQuickSearchArtworks(state, payload) {
      state.quickSearchArtworks = payload;
    },
  },
  actions: {
    async apiGetViewingRooms(
      { commit },
      { sort = "name", order = "asc", page = "1", status = "" }
    ) {
      let success = false;
      commit("setLoading", true);
      commit("setHasMorePages", false);
      let query = {
        sort,
        order,
        page,
        status,
      };
      let queryStr = "";
      for (let key in query) {
        if (query[key] != "") {
          queryStr += `${key}=${query[key]}&`;
        }
      }
      await Vue.axios
        .get(`/api/admin/viewing-rooms?${queryStr}`)
        .then(res => res.data)
        .then(data => {
          success = true;
          commit("setPage", page);
          commit("setHasMorePages", data.response.hasMorePages);
          commit("setViewingRooms", data.response.data);
        })
        .finally(() => {
          commit("setLoading", false);
        });
      return success;
    },
    // async apiGetSearchedViewingRooms({ commit }, { search = "", excludeIdx }) {
    //   let success = false,
    //     limit = "all";
    //   commit("setChangesLoading", true);

    //   let excludeIdxString = "";
    //   if (excludeIdx.length > 0) {
    //     excludeIdxString = excludeIdx
    //       .map(el => {
    //         return el.id;
    //       })
    //       .join(",");
    //   }

    //   await Vue.axios
    //     .get(
    //       `/api/admin/viewing-rooms?limit=${limit}&search=${search}&exclude_ids=${excludeIdxString}`
    //     )
    //     .then(res => res.data)
    //     .then(data => {
    //       success = true;
    //       commit("setSearchedViewingRooms", data.response);
    //     })
    //     .finally(() => {
    //       commit("setChangesLoading", false);
    //     });
    //   return success;
    // },
    async apiPostViewingRoom({ commit }, payload) {
      let result = false;
      let data = {
        ...payload,
        is_published: payload.is_published != null ? payload.is_published : "0",
      };
      commit("setChangesLoading", true);
      await Vue.axios
        .post("/api/admin/viewing-room", data)
        .then(res => res.data)
        .then(data => {
          result = true;
          commit("setError", null);
        })
        .catch(e => {
          let response = e.response;
          if (response.status === 422) {
            commit("setError", response.data.detail);
          }
        })
        .finally(() => {
          commit("setChangesLoading", false);
        });
      return result;
    },
    async apiGetViewingRoom({ commit }, id) {
      let result = false;
      await Vue.axios
        .get(`/api/admin/viewing-room/${id}`)
        .then(res => res.data)
        .then(data => {
          result = true;
          commit("setViewingRoom", data.response);
        });
      return result;
    },
    async apiPutViewingRoom({ commit }, payload) {
      commit("setChangesLoading", true);
      let result = false;
      await Vue.axios
        .put(`/api/admin/viewing-room/${payload.id}`, payload)
        .then(res => res.data)
        .then(data => {
          result = true;
          commit("setError", null);
        })
        .catch(e => {
          let response = e.response;
          if (response.status === 422) {
            commit("setError", response.data.detail);
          }
        })
        .finally(() => {
          commit("setChangesLoading", false);
        });
      return result;
    },
    async apiDeleteViewingRoom({ commit }, id) {
      let result = { success: false, message: null };
      await Vue.axios
        .delete(`/api/admin/viewing-room/${id}`)
        .then(res => res.data)
        .then(data => {
          result.success = true;
          result.message = data.message;
        });
      return result;
    },
    async apiGetQuickSearchArtworks({ commit }, { id = "", search = "" }) {
      let result = false;
      if (search) {
        let query = {
          id: id ?? "",
          search,
        };
        let queryStr = "";
        for (let key in query) {
          if (query[key] != "") {
            queryStr += `${key}=${query[key]}&`;
          }
        }
        await Vue.axios
          .get(`/api/admin/viewing-room/search?${queryStr}`)
          .then(res => res.data)
          .then(data => {
            result = true;
            commit("setQuickSearchArtworks", data.response);
          });
      }
      return result;
    },
  },
};
