import Vue from 'vue'
import { initials } from "@/helper";
export default {
    namespaced: true,
    state: {
        user: {}
    },
    getters: { getUserInfo(state) { return state.user } },
    mutations: {
        setUserInfo(state, payload) {
            state.user = {
                ...payload,
                initials: initials(payload.first_name, payload.last_name),
                name_abbreviation: `${payload.first_name} ${initials(payload.last_name)}.`
            }
        },
    },
    actions: {
        async apiGetUserInfo({ commit }) {
            let result = false
            await Vue.axios.get(`/api/admin/dashboard`,)
                .then((res) => res.data)
                .then((data) => {
                    commit('setUserInfo', data.response.logged_user)
                })
            return result
        },
    }
}