<template>
  <svg :width="width" :height="height" :viewBox="viewBox">
    <path
      :fill="color"
      fill-rule="evenodd"
      d="M23.62409111 0H3.37590887C2.47778127.0008993 1.6169671.34580692.98356015.95855681c-.63340693.61274991-.98730051 1.44293527-.98353033 2.3072261v19.48549678C.00198433 24.5447187 1.51227254 25.9981191 3.37590889 26h10.1240911v-8.94763092h-3.37587907v-4.0609004H13.5V9.74957344c-.00188269-1.29269834.53042039-2.53307154 1.47960971-3.44778809.9491893-.91471655 2.23736539-1.42870615 3.5806628-1.42870488h3.38651734V8.9305683h-1.6985778c-.92907596 0-1.68793953-.0819005-1.68793953.81218008v3.24872031h4.21984883l-1.68793954 4.06090038h-2.5319093v8.9305683h5.06381861c1.86363634-.0018809 3.37392454-1.4552813 3.37587907-3.2487203V3.26578291c.00377017-.86429083-.35012341-1.69447619-.98353035-2.3072261C25.3830329.34580692 24.52221873.00089931 23.62409111 0z"
    />
  </svg>
</template>
<script>
export default {
  props: {
    viewBox: { type: String, default: "0 0 27 26" },
    width: { type: String, default: "27" },
    height: { type: String, default: "26" },
    color: { type: String, default: "#fff" },
  },
};
</script>
