import Vue from "vue";
export default {
  namespaced: true,
  state: {
    searchResultList: {},
    tags: [],
  },
  getters: {
    getSearchResultList(state) {
      return state.searchResultList;
    },

    getTags(state) {
      return state.tags;
    },
  },
  mutations: {
    setSearchResultList(state, { data, key, page }) {
      if (page > 1) {
        let resultItem = state.searchResultList[key];
        resultItem.list = resultItem.list.concat(data[key].list);
        resultItem.hasMorePages = data[key].hasMorePages;
        data[key] = resultItem;
      }
      const count =
        data.categories.count + data.artists.count + data.artworks.count + data.publications.count;
      if (count === 0) {
        state.searchResultList = {
          result: false,
          count,
          ...data,
        };
      } else {
        state.searchResultList = {
          result: true,
          count,
          ...data,
        };
      }
    },

    setTags(state, data) {
      state.tags = data;
    },
  },
  actions: {
    async apiGetSearchList({ commit, dispatch }, { search = "", key = "", page = "" }) {
      let url = `/api/public/search?search=${search}`;
      if (search && key) url += `&type=${key}&page=${page}`;
      try {
        const response = await Vue.axios.get(url);
        const data = response?.data.response ?? {};

        let artistsImages = data.artists.list.map(artist => {
          return {
            image: artist.search_image,
            width: "768",
            height: "768",
          };
        });
        let artworksImages = data.artworks.list.map(artwork => {
          return {
            image: artwork.search_image,
            width: "768",
            height: "768",
          };
        });
        // let blogsImages = data.blogs.list.map(blog => {
        //   return {
        //     image: blog.preview_image,
        //     width: "768",
        //     height: "768",
        //   };
        // });
        let categoriesImages = data.categories.list.map(category => {
          return {
            image: category.image,
            width: "768",
            height: "768",
          };
        });

        [artistsImages, artworksImages, categoriesImages] = await Promise.all([
          dispatch("publicImageResizer/apiImageResizer", artistsImages, { root: true }),
          dispatch("publicImageResizer/apiImageResizer", artworksImages, { root: true }),
          dispatch("publicImageResizer/apiImageResizer", categoriesImages, { root: true }),
        ]);

        data.artists.list.forEach((artist, index) => {
          artist.search_image = artistsImages[index];
        });
        data.artworks.list.forEach((artwork, index) => {
          artwork.search_image = artworksImages[index];
        });
        data.categories.list.forEach((category, index) => {
          category.image = categoriesImages[index];
        });

        commit("setSearchResultList", { data, key, page });
      } catch (e) {
        const response = e.response;
        if (response.status === 422) {
          console.log(response.data.detail.search[0]);
        }
      }
    },
    async apiGetSearchTags({ commit, dispatch }, { search = "", tags = "", key = "", page = "" }) {
      let url = `/api/public/search-by-tags?`;
      if (search) url += `search=${search}`;
      if (search && tags) url += "&";
      if (tags) url += `tags=${tags}`;
      if (tags && key) url += `&type=${key}&page=${page}`;

      try {
        const response = await Vue.axios.get(url);

        const data = response?.data.response ?? {};

        let artistsImages = data.artists.list.map(artist => {
          return {
            image: artist.search_image,
            width: "768",
            height: "768",
          };
        });
        let artworksImages = data.artworks.list.map(artwork => {
          return {
            image: artwork.search_image,
            width: "768",
            height: "768",
          };
        });
        let categoriesImages = data.categories.list.map(category => {
          return {
            image: category.image,
            width: "768",
            height: "768",
          };
        });

        [artistsImages, artworksImages, categoriesImages] = await Promise.all([
          dispatch("publicImageResizer/apiImageResizer", artistsImages, { root: true }),
          dispatch("publicImageResizer/apiImageResizer", artworksImages, { root: true }),
          dispatch("publicImageResizer/apiImageResizer", categoriesImages, { root: true }),
        ]);

        data.artists.list.forEach((artist, index) => {
          artist.search_image = artistsImages[index];
        });
        data.artworks.list.forEach((artwork, index) => {
          artwork.search_image = artworksImages[index];
        });
        data.categories.list.forEach((category, index) => {
          category.search_image = categoriesImages[index];
        });

        commit("setSearchResultList", { data, key, page });
      } catch (e) {
        const response = e.response;
        if (response.status === 422) {
          console.log(response.data.detail.search[0]);
        }
      }
    },
    async apiGetTags({ commit }) {
      await Vue.axios.get(`/api/public/tags`).then(response => {
        commit("setTags", response?.data.response ?? []);
      });
    },
  },
};

