const General = () => import("@/views/exteriors/General.vue");
const Exteriors = () => import("@/views/exteriors/Exteriors.vue");

export default [
  {
    path: "/exteriors",
    redirect: "/exteriors/1",
    component: General,
    children: [
      {
        path: ":id",
        name: "Exteriors",
        component: Exteriors,
        meta: {
          layout: "base",
          footer: false,
        },
      },
    ],
  },
];
