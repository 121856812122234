export function infiniteScroll(el) {
  let result = false;
  const listElm = document.querySelector(el);
  const sizeScrollActive = 100;
  if (listElm.scrollTop + listElm.clientHeight + sizeScrollActive >= listElm.scrollHeight) {
    result = true;
  }
  return result;
}

export function initials(name, lastName) {
  let letterName = "",
    letterLastName = "";
  letterName = name[0].toUpperCase();
  if (lastName) {
    letterLastName = lastName[0].toUpperCase();
  }
  return letterName + letterLastName;
}

export function sortedColumns(list, columnsAmount) {
  const result = [];
  const itemsAmount = list.length;
  let itemsAmountLeft = itemsAmount;
  let columnsAmountLeft = columnsAmount;
  let latestItemStartIndex = 0;

  let maxItemsInColumn = Math.ceil(itemsAmount / columnsAmount);

  for (let columnIndex = 0; columnIndex < columnsAmount; columnIndex++) {
    const itemsAmountWillLeft = itemsAmountLeft - maxItemsInColumn;
    const columnsAmountWilLeft = columnsAmountLeft - 1;

    if (itemsAmountWillLeft / columnsAmountWilLeft < maxItemsInColumn - 1) {
      maxItemsInColumn--;
    }

    const itemEndIndex = latestItemStartIndex + maxItemsInColumn;
    result[columnIndex] = list.slice(latestItemStartIndex, itemEndIndex);

    latestItemStartIndex = itemEndIndex;
    itemsAmountLeft -= maxItemsInColumn;
    columnsAmountLeft--;
  }
  return result;
}
