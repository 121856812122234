<template>
  <div class="subscribe">
    <p class="subscribe__text">Subscribe for {{ text }}</p>
    <ValidationObserver v-slot="{ invalid }" tag="div" class="subscribe__email" ref="observer">
      <label class="subscribe__email-label">
        <span class="subscribe__email-label-text">Your Email:</span>
        <ValidationProvider tag="div" mode="passive" :rules="{ email: true }" v-slot="{ errors }">
          <BaseInput
            class="subscribe__input"
            :class="{ 'form-control--invalid': errors || errors[0] }"
            :validateError="errors[0] || errorServer"
            :element="email"
            v-model="email"
            @input="val => (email = val)"
          ></BaseInput>
        </ValidationProvider>
      </label>
      <BaseButton
        class="subscribe__button button--outline-white button--lg"
        text="Sign up"
        @click="signUp"
      ></BaseButton>
      <p class="subscribe__message">{{ message }}</p>
    </ValidationObserver>
  </div>
</template>

<script>
import BaseInput from "@/components/inputs/BaseInput.vue";
import BaseButton from "@/components/buttons/BaseButton.vue";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { mapActions } from "vuex";
export default {
  components: {
    BaseInput,
    BaseButton,
    ValidationObserver,
    ValidationProvider,
  },
  props: {
    text: {
      type: String,
      default: "Newsletter",
    },
  },
  data() {
    return {
      email: "",
      errorServer: "",
      message: "",
    };
  },
  methods: {
    ...mapActions("publicSubscribe", ["apiSubscribe"]),
    async signUp() {
      const validate = await this.$refs.observer.validate();
      if (validate) {
        const success = await this.apiSubscribe({ email: this.email });
        if (success) {
          if (success.status === "error") {
            this.errorServer = "Sorry! But you have already subscribed.";
            setTimeout(() => {
              this.errorServer = "";
            }, 5000);
          } else {
            this.message = "You successfully subscribed.";
            setTimeout(() => {
              this.message = "";
            }, 5000);
            this.email = "";
          }
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.subscribe {
  position: relative;
  width: 100%;
  height: 105px;
  padding: 25px 25px 30px;
  background-color: $lightBlack;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: $md) {
    padding: 20px 25px 40px;
    height: max-content;
  }
  @media (max-width: $sm) {
    padding: 20px 25px 40px;
    height: max-content;
    flex-direction: column;
  }
  &__text {
    font-family: $fontBodoni;
    font-size: 2.5rem;
    line-height: 1.92;
    color: $white;
    letter-spacing: -0.42px;
    padding-right: 10rem;
    @media (max-width: $sm) {
      padding-right: 0;
      padding-bottom: 3rem;
    }
  }
  &__message {
    font-family: $fontOpenSans;
    font-size: 1.6rem;
    line-height: 2;
    color: $white;
    white-space: nowrap;
    position: absolute;
    bottom: 0;
    left: 0;
    transform: translateY(100%);
  }
  &__email {
    position: relative;
    display: flex;
    border-bottom: 1px solid $white;
    &-label {
      display: flex;
      &-text {
        font-family: $fontOpenSans;
        font-size: 1.6rem;
        line-height: 2.75;
        color: $white;
        white-space: nowrap;
      }
    }
  }

  &__input {
    width: 100%;
    max-width: 28rem;
    margin: 0;
    &::v-deep {
      .form-control__input {
        height: 4.4rem;
        padding: 0 10px;
        font-family: $fontOpenSans;
        font-size: 1.6rem;
        line-height: 2.75;
        color: $white;
        border: none;
      }
      .form-control__label {
        display: none;
      }
      .form-control__error {
        font-size: 1.6rem;
        line-height: 2;
      }
    }
  }
  &__button {
    width: max-content;
    height: 4.4rem;
    border: none;
    padding: 0 5px;
    font-size: 1.8rem;
    font-weight: 600;
    line-height: 1.5;
    opacity: 0.9;
    white-space: nowrap;
  }
}
</style>
