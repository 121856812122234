const General = () => import("@/views/admin/General.vue");

const Dashboard = () => import("@/views/admin/Dashboard.vue");

const Collection = () => import("@/views/admin/collection/Collection.vue");
const Artworks = () => import("@/views/admin/collection/artworks/Artworks.vue");
const Artists = () => import("@/views/admin/collection/artists/Artists.vue");
const Categories = () => import("@/views/admin/collection/categories/Categories.vue");
const ViewingRooms = () => import("@/views/admin/collection/viewingRooms/ViewingRooms.vue");

const Content = () => import("@/views/admin/content/Content.vue");
const Blogs = () => import("@/views/admin/content/blogs/Blogs.vue");
const Publications = () => import("@/views/admin/content/editorials/Editorials.vue");
const Tags = () => import("@/views/admin/content/tags/Tags.vue");
const Pages = () => import("@/views/admin/content/pages/Pages.vue");
export default [
  {
    path: "/admin",
    component: General,
    children: [
      {
        path: "",
        name: "Dashboard",
        component: Dashboard,
        meta: {
          layout: "admin",
        },
      },
      {
        path: "collection",
        redirect: "/admin/collection/artworks",
        name: "Collection",
        component: Collection,
        meta: {
          layout: "admin",
        },
        children: [
          {
            path: "artworks",
            name: "Artworks",
            component: Artworks,
            meta: {
              layout: "admin",
            },
          },
          {
            path: "creators",
            name: "Artists",
            component: Artists,
            meta: {
              layout: "admin",
            },
          },
          {
            path: "categories",
            name: "Categories",
            component: Categories,
            meta: {
              layout: "admin",
            },
          },
          {
            path: "viewing-rooms",
            name: "ViewingRooms",
            component: ViewingRooms,
            meta: {
              layout: "admin",
            },
          },
        ],
      },
      {
        path: "content",
        redirect: "/admin/content/blogs",
        name: "Content",
        component: Content,
        meta: {
          layout: "admin",
        },
        children: [
          {
            path: "blogs",
            name: "Blog",
            component: Blogs,
            meta: {
              layout: "admin",
            },
          },
          {
            path: "publications",
            name: "Publications",
            component: Publications,
            meta: {
              layout: "admin",
            },
          },
          {
            path: "tag-manager",
            name: "Tags",
            component: Tags,
            meta: {
              layout: "admin",
            },
          },
          {
            path: "pages",
            name: "Pages",
            component: Pages,
            meta: {
              layout: "admin",
            },
          },
        ],
      },
    ],
  },
];
