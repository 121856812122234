import Vue from "vue";
export default {
  namespaced: true,
  state: {
    loading: false,
    pages: [],
    pagesHeaders: [
      {
        key: "page_name",
        title: "Page",
        sortable: true,
      },
      {
        key: "slug",
        title: "URL",
        sortable: true,
      },
      {
        key: "indent",
        className: "indent",
      },
      {
        key: "edit",
        title: "Edit",
        sortable: false,
        className: "xsm",
        variables: "icon",
        icon: "IconEdit",
      },
    ],
    page: null,
    changesLoading: false,
    hasMorePages: false,
    pageNumber: 1,
    serverError: null,
  },
  getters: {
    getLoading(state) {
      return state.loading;
    },
    getPages(state) {
      return state.pages;
    },
    getPagesHeaders(state) {
      return state.pagesHeaders;
    },
    getPage(state) {
      return state.page;
    },
    getChangesLoading(state) {
      return state.changesLoading;
    },
    getHasMorePages(state) {
      return state.hasMorePages;
    },
    getServerError(state) {
      return state.serverError;
    },
  },
  mutations: {
    setLoading(state, payload) {
      state.loading = payload;
    },
    setPages(state, payload) {
      let pages = payload;
      if (state.page > 1) {
        state.pages = state.pages.concat(pages);
      } else {
        state.pages = pages;
      }
    },
    initialPage(state) {
      state.page = null;
    },
    setPage(state, payload) {
      state.page = payload;
    },
    setChangesLoading(state, payload) {
      state.changesLoading = payload;
    },
    setPageNumber(state, payload) {
      state.pageNumber = payload;
    },
    setHasMorePages(state, payload) {
      state.hasMorePages = payload;
    },
    setError(state, payload) {
      state.serverError = payload;
    },
  },
  actions: {
    async apiGetPages({ commit }, { sort = "title", order = "asc", page = "1" }) {
      let success = false;
      commit("setLoading", true);
      commit("setHasMorePages", false);
      await Vue.axios
        .get(`/api/admin/pages?sort=${sort}&order=${order}&page=${page}`)
        .then(res => res.data)
        .then(data => {
          success = true;
          commit("setPageNumber", page);
          commit("setHasMorePages", data.response.hasMorePages);
          commit("setPages", data.response.response);
        })
        .finally(() => {
          commit("setLoading", false);
        });
      return success;
    },
    async apiPostPage({ commit }, payload) {
      commit("setChangesLoading", true);
      let result = {
        success: false,
        page: null,
        error: null,
      };
      await Vue.axios
        .post("/api/admin/page", payload)
        .then(res => res.data)
        .then(data => {
          result.success = true;
          result.page = data.response.page;
        })
        .catch(e => {
          if (e.response.status === 422) {
            if (e.response.data?.detail?.title[0]) {
              result.error = "The page has already been taken";
              commit("setError", "The page has already been taken");
            }
          }
        })
        .finally(() => {
          commit("setChangesLoading", false);
        });
      return result;
    },
    async apiGetPage({ commit }, id) {
      let result = false;
      await Vue.axios
        .get(`/api/admin/page/${id}`)
        .then(res => res.data)
        .then(data => {
          result = true;
          commit("setPage", { ...data.response, id });
        });
      return result;
    },
    async apiPutPage({ commit }, payload) {
      commit("setChangesLoading", true);
      let result = false;
      await Vue.axios
        .put(`/api/admin/page/${payload.id}`, payload)
        .then(res => res.data)
        .then(data => {
          result = true;
          commit("setError", null);
        })
        .catch(e => {
          let response = e.response;
          if (response.status === 422) {
            if (response.data?.detail?.title[0]) {
              commit("setError", "The page has already been taken");
            }
          }
        })
        .finally(() => {
          commit("setChangesLoading", false);
        });
      return result;
    },
    async apiDeletePage({ commit }, id) {
      let result = { success: false, message: null };
      await Vue.axios
        .delete(`/api/admin/page/${id}`)
        .then(res => res.data)
        .then(data => {
          result.success = true;
          result.message = data.message;
        });
      return result;
    },
  },
};

