<template>
  <svg :width="width" :height="height" :viewBox="viewBox" fill="none">
    <g :fill="color" transform="rotate(44.999887 14.54425657 14.54425641)">
      <path
        :stroke="color"
        stroke-linecap="butt"
        stroke-linejoin="miter"
        stroke-width="3"
        d="M14.25964103 4.86733333V24.2211795"
      />
      <path
        :stroke="color"
        stroke-linecap="butt"
        stroke-linejoin="miter"
        stroke-width="3"
        d="M24.22117965 14.25964647l-19.35384616-.00001088"
      />
    </g>
  </svg>
</template>
<script>
export default {
  props: {
    viewBox: { type: String, default: "0 0 30 30" },
    width: { type: String, default: "30" },
    height: { type: String, default: "30" },
    color: { type: String, default: "#000" },
  },
};
</script>