<template>
  <svg :width="width" :height="height" :viewBox="viewBox">
    <g :fill="color">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M3.68784 0.135757L0.127804 3.92316C0.0453979 4.01075 -7.99999e-08 4.12769 0 4.25238C7.00001e-08 4.37706 0.0453981 4.494 0.127804 4.58159L0.389916 4.86051C0.560711 5.042 0.838303 5.042 1.00884 4.86051L3.99834 1.68014L6.99116 4.86403C7.07357 4.95163 7.18342 5 7.30056 5C7.41783 5 7.52768 4.95163 7.61015 4.86403L7.8722 4.58512C7.9546 4.49745 8 4.38059 8 4.2559C8 4.13121 7.9546 4.01428 7.8722 3.92668L4.30891 0.135757C4.22624 0.0479516 4.11587 -0.000275645 3.99853 1.18518e-06C3.88075 -0.000275515 3.77044 0.0479518 3.68784 0.135757V0.135757Z"
      />
    </g>
  </svg>
</template>
<script>
export default {
  props: {
    viewBox: { type: String, default: "0 0 8 5" },
    width: { type: String, default: "8" },
    height: { type: String, default: "5" },
    color: { type: String },
  },
};
</script>