//const CategoryMenu = () => import("@/views/category/CategoryMenu.vue");
const Collection = () => import("@/views/category/Collection.vue");
const Category = () => import("@/views/category/Category.vue");
const Artwork = () => import("@/views/category/Artwork.vue");

export default [
  // {
  //   path: "/categories",
  //   name: "CategoryMenu",
  //   component: CategoryMenu,
  //   meta: {
  //     layout: "base",
  //     footer: false,
  //   },
  // },
  {
    path: "/categories", // categories
    name: "Collection",
    component: Collection,
    meta: {
      layout: "base",
      footer: true,
    },
  },
  {
    path: "/art", // categories
    name: "Art",
    redirect: "/categories",
    component: Collection,
    meta: {
      layout: "base",
      footer: true,
    },
  },
  {
    path: "/art/:categoryName",
    name: "Category",
    component: Category,
    meta: {
      layout: "base",
      footer: true,
    },
  },
  {
    path: "/art/:categoryName/:artworkSlug",
    name: "SpecificArtwork",
    component: Artwork,
    meta: {
      layout: "base",
      footer: true,
    },
  },
];
