import Vue from "vue";
export default {
  namespaced: true,
  state: {
    artistsList: [],
    specificArtist: {},
    relatedArtworks: [],
    nextArtist: {},
    previousArtist: {},
  },
  getters: {
    getArtistsList(state) {
      return state.artistsList;
    },

    getSpecificArtist(state) {
      return state.specificArtist;
    },

    getRelatedArtworks(state) {
      return state.relatedArtworks;
    },
    getNextArtist(state) {
      return state.nextArtist;
    },
    getPreviousArtist(state) {
      return state.previousArtist;
    },
  },
  mutations: {
    setArtistsList(state, payload) {
      state.artistsList = payload;
    },

    setSpecificArtist(state, payload) {
      if (payload === null) {
        state.specificArtist = { id: 0, name: "Non-existent Creator", biography: "", images: [] };
      } else {
        state.specificArtist = payload;
      }
    },

    setRelatedArtworks(state, payload) {
      state.relatedArtworks = payload;
    },
    setNextArtist(state, payload) {
      state.nextArtist = payload;
    },
    setPreviousArtist(state, payload) {
      state.previousArtist = payload;
    },
  },
  actions: {
    async apiGetArtistsList({ commit }) {
      await Vue.axios.get("/api/public/artists").then(response => {
        commit("setArtistsList", response?.data.response ?? []);
      });
    },

    async apiGetSpecificArtist({ commit }, slug) {
      await Vue.axios.get(`/api/public/artists/${slug}`).then(response => {
        commit("setSpecificArtist", response?.data.response.artist);
        commit("setNextArtist", response?.data.response.next);
        commit("setPreviousArtist", response?.data.response.previous);
        commit("setRelatedArtworks", response?.data.response.artworks);
      });
    },
  },
};
