<template>
  <svg :width="width" :height="height" :viewBox="viewBox">
    <g :fill="color">
      <path
        fill-rule="evenodd"
        d="M24.57336426 0H6.42651367C4.71862793 0 3.08117676.68096924 1.87524414 1.89263916.66955566 3.10424805-.00524902 4.74682617 0 6.45758056v9.08483887c-.00524902 1.7107544.66955566 3.35333252 1.87524414 4.56494141C3.08117676 21.31896973 4.71862793 22 6.42651367 22h18.14685059c1.70800781 0 3.34558105-.68103027 4.5513916-1.89263916 1.20581055-1.21160889 1.8803711-2.85418701 1.87512207-4.5649414V6.45758056c.00524902-1.7107544-.66931152-3.35333252-1.87512207-4.56494141C27.9189453.68096924 26.28137207 0 24.57336426 0zm-4.3618164 11.44262695l-8.48706056 4.055542c-.10571289.04956054-.22937011.0415039-.32775878-.02124024-.09863282-.06280517-.15820313-.17163086-.15820313-.28857422V6.8277588c0-.11853027.0612793-.22857666.1619873-.29077149.10058594-.06231689.2261963-.06799316.33203126-.01501464l8.48706054 4.309021c.11743164.057312.19140625.17749023.18969727.30841064-.00183106.13085937-.07885742.2489624-.1977539.30322265z"
      />
    </g>
  </svg>
</template>
<script>
export default {
  props: {
    viewBox: { type: String, default: "0 0 31 22" },
    width: { type: String, default: "31" },
    height: { type: String, default: "22" },
    color: { type: String, default: "#fff" },
  },
};
</script>
