import Vue from "vue";
import router from "@/router";

export default {
  namespaced: true,
  state: {
    blogsList: [],
    lastBlog: {},
    blog: {},
    nextBlog: {},
    hasMorePages: false,
  },
  getters: {
    getBlogsList(state) {
      return state.blogsList;
    },
    getHasMorePages(state) {
      return state.hasMorePages;
    },
    getLastBlog(state) {
      return state.lastBlog;
    },
    getBlog(state) {
      return state.blog;
    },
    getNextBlog(state) {
      return state.nextBlog;
    },
  },
  mutations: {
    setBlogsList(state, { data, page }) {
      if (page > 1) state.blogsList = state.blogsList.concat(data);
      else state.blogsList = data;
    },
    setHasMorePages(state, data) {
      state.hasMorePages = data;
    },
    setLastBlog(state, data) {
      state.lastBlog = data;
    },
    setBlog(state, data) {
      state.blog = data;
    },
    setNextBlog(state, data) {
      state.nextBlog = data;
    },
  },
  actions: {
    async apiGetBlogsList({ commit, dispatch }, { page, lastBlogId = "" }) {
      let url = `/api/public/blog?page=${page}`;
      if (lastBlogId) url += `&last_article_id=${lastBlogId}`;
      let images = [];
      let last_article = null;
      let articles = [];
      let hasMorePages = null;
      await Vue.axios.get(url).then(response => {
        last_article = response?.data?.last_article ?? null;
        articles = response?.data?.articles?.data ?? [];
        hasMorePages = response?.data?.articles?.hasMorePages ?? null;
        if (last_article) {
          images.push({
            image: last_article.image,
            width: `${window.innerWidth}`,
            height: `${window.innerWidth}`,
          });
        }
        articles.map(article => {
          let width = `${Math.round(window.innerWidth * 0.33333)}`;
          let height = `${Math.round(window.innerWidth * 0.33333)}`;
          if (window.innerWidth > 1440) {
            width = `${Math.round(window.innerWidth * 0.25)}`;
            height = `${Math.round(window.innerWidth * 0.25)}`;
          }
          if (window.innerWidth <= 1024) {
            width = `${Math.round(window.innerWidth * 0.5)}`;
            height = `${Math.round(window.innerWidth * 0.5)}`;
          }
          if (window.innerWidth <= 425) {
            width = `${window.innerWidth}`;
            height = 480;
          }
          images.push({
            image: article.image,
            width,
            height,
          });
        });
      });
      if (images.length) {
        images = await dispatch("publicImageResizer/apiImageResizer", images, { root: true });
      }
      if (last_article) {
        last_article.image = images[0];
        commit("setLastBlog", last_article);
      }
      articles.map((article, index) => {
        if (last_article) article.image = images[index + 1];
        else article.image = images[index];
      });
      commit("setBlogsList", { data: articles, page });
      commit("setHasMorePages", hasMorePages);
    },

    async apiGetBlog({ commit, dispatch }, slug) {
      let images = [];
      let article = {};
      await Vue.axios.get(`/api/public/blog/${slug}`).then(response => {
        if (response?.data.response?.article?.id) {
          article = response.data.response.article;
          images.push({
            image: article.image,
            width: `${window.innerWidth}`,
            height: `${window.innerWidth}`,
          });
          commit("setNextBlog", response.data.response.next_article);
        } else router.push("/404");
      });
      images = await dispatch("publicImageResizer/apiImageResizer", images, { root: true });
      article.image = images[0];
      commit("setBlog", article);
    },
  },
};
