const About = () => import("@/views/about/About.vue");
const History = () => import("@/views/about/History.vue");
const Friends = () => import("@/views/about/Friends.vue");

export default [
  {
    path: "/about",
    name: "About",
    component: About,
    meta: {
      layout: "base",
      footer: true,
    },
  },
  {
    path: "/about/the-history",
    name: "History",
    component: History,
    meta: {
      layout: "base",
      footer: true,
    },
  },
  {
    path: "/about/friends-of-grottas",
    name: "Friends",
    component: Friends,
    meta: {
      layout: "base",
      footer: true,
    },
  },
];
