import Vue from "vue";
import VueCookies from "vue-cookies";

import VueRouter from "vue-router";

const Home = () => import("@/views/Home.vue");
const Login = () => import("@/views/Login.vue");
const NotFound = () => import("@/views/NotFound.vue");
const Interview = () => import("@/views/Interview.vue");
const PrivacyPolicy = () => import("@/views/PrivacyPolicy.vue");
const TermsAndConditions = () => import("@/views/TermsAndConditions.vue");

import admin from "./admin.js";
import auth from "./auth.js";
import exteriors from "./exteriors.js";
import interiors from "./interiors.js";
import library from "./library.js";
import categories from "./categories.js";
import artists from "./artists.js";
import about from "./about.js";
import redirectSeo from "./redirectSeo.js";
import viewingRoom from "./viewingRoom.js";

Vue.use(VueCookies);
Vue.use(VueRouter);

const routes = [
  ...exteriors,
  ...interiors,
  ...library,
  ...categories,
  ...artists,
  ...about,
  ...viewingRoom,
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: {
      layout: "base",
      footer: false,
    },
  },
  {
    path: "/404",
    name: "404",
    component: NotFound,
    meta: {
      layout: "base",
      footer: false,
    },
  },
  {
    path: "/interview",
    name: "InterviewPage",
    component: Interview,
    meta: {
      layout: "base",
      footer: true,
      breadcrumbsBlack: true,
    },
  },
  {
    path: "/privacy-policy",
    name: "PrivacyPolicy",
    component: PrivacyPolicy,
    meta: {
      layout: "base",
      footer: true,
    },
  },
  {
    path: "/terms-and-conditions",
    name: "TermsAndConditions",
    component: TermsAndConditions,
    meta: {
      layout: "base",
      footer: true,
    },
  },
  ...admin,
  {
    path: "/login",
    name: "Login",
    component: Login,
    meta: {
      layout: "empty",
    },
    beforeEnter: (to, from, next) => {
      const token = Vue.$cookies.get("token") || null;
      if (token === null || token === "null") {
        next();
      } else next({ path: "/admin" });
    },
  },
  ...auth,
  ...redirectSeo,
  {
    path: "*",
    redirect: "/404",
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.layout === "admin")) {
    let token = Vue.$cookies.get("token");
    if (token == null) {
      next({
        path: "/login",
      });
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
