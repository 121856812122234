import Vue from "vue";
export default {
  namespaced: true,
  state: {
    loading: false,
    categories: [],
    categoriesHeaders: [
      {
        key: "title",
        title: "Title",
        sortable: true,
      },
      {
        key: "artworks_count",
        title: "Items",
        sortable: true,
      },
      {
        key: "indent",
        className: "indent",
      },
      {
        key: "edit",
        title: "Edit",
        sortable: false,
        className: "xsm",
        variables: "icon",
        icon: "IconEdit",
      },
      {
        key: "delete",
        title: "Delete",
        sortable: false,
        className: "xsm",
        variables: "icon",
        icon: "IconDelete",
      },
    ],
    matchedCategories: [],
    category: null,
    changesLoading: false,
    hasMorePages: false,
    page: 1,
    categoryTypes: [],
  },
  getters: {
    getLoading(state) {
      return state.loading;
    },
    getCategories(state) {
      return state.categories;
    },
    getCategoriesHeaders(state) {
      return state.categoriesHeaders;
    },
    getMatchedCategories(state) {
      return state.matchedCategories;
    },
    getCategory(state) {
      return state.category;
    },
    getChangesLoading(state) {
      return state.changesLoading;
    },
    getHasMorePages(state) {
      return state.hasMorePages;
    },
    getCategoryTypes(state) {
      return state.categoryTypes;
    },
  },
  mutations: {
    setLoading(state, payload) {
      state.loading = payload;
    },
    setCategories(state, payload) {
      let categories = payload;
      if (state.page > 1) {
        state.categories = state.categories.concat(categories);
      } else {
        state.categories = categories;
      }
    },
    initialCategory(state) {
      state.category = null;
    },
    setMatchedCategories(state, payload) {
      state.matchedCategories = payload;
    },
    setCategory(state, payload) {
      state.category = payload;
    },
    setChangesLoading(state, payload) {
      state.changesLoading = payload;
    },
    setPage(state, payload) {
      state.page = payload;
    },
    setHasMorePages(state, payload) {
      state.hasMorePages = payload;
    },
    setCategoryTypes(state, payload) {
      payload.forEach(type => {
        type.key = type.id;
      });
      state.categoryTypes = payload;
    },
  },
  actions: {
    async apiGetCategories({ commit }, { sort = "title", order = "asc", page = "1" }) {
      let success = false;
      commit("setLoading", true);
      commit("setHasMorePages", false);

      await Vue.axios
        .get(`/api/admin/categories?sort=${sort}&order=${order}&page=${page}`)
        .then(res => res.data)
        .then(data => {
          success = true;
          commit("setPage", page);
          commit("setHasMorePages", data.response.hasMorePages);
          commit("setCategories", data.response.data);
        })
        .finally(() => {
          commit("setLoading", false);
        });
      return success;
    },
    async apiGetMatchedCategories({ commit }, { search, excludeIdx }) {
      commit("setLoading", true);
      let limit = "all";
      let excludeIdxString = "";
      if (excludeIdx.length > 0) {
        excludeIdxString = excludeIdx
          .map(el => {
            return el.id;
          })
          .join(",");
      }

      await Vue.axios
        .get(
          `/api/admin/categories?search=${search}&exclude_ids=${excludeIdxString}&limit=${limit}`
        )
        .then(res => res.data)
        .then(data => {
          commit("setMatchedCategories", data.response.data);
        })
        .finally(() => {
          commit("setLoading", false);
        });
    },
    async apiGetCategory({ commit }, id) {
      let result = false;
      await Vue.axios
        .get(`/api/admin/category/${id}`)
        .then(res => res.data)
        .then(data => {
          result = true;
          commit("setCategory", data.response);
        });
      return result;
    },
    async apiPutCategory({ commit }, payload) {
      commit("setChangesLoading", true);
      let data = {
        result: false,
        error: null,
      };
      await Vue.axios
        .put(`/api/admin/category/${payload.id}`, payload)
        .then(res => res.data)
        .then(() => {
          data.result = true;
          data.error = null;
        })
        .catch(e => {
          data.result = false;
          if (e.response.data.detail?.category_type_id) {
            data.error = e.response.data.detail?.category_type_id[0];
          } else {
            data.error = response.data.detail;
          }
        })
        .finally(() => {
          commit("setChangesLoading", false);
        });
      return data;
    },

    async apiPostCategory({ commit }, payload) {
      let resultData = {
        result: false,
        error: null,
      };
      let data = {
        ...payload,
      };
      commit("setChangesLoading", true);
      await Vue.axios
        .post("/api/admin/category", data)
        .then(res => res.data)
        .then(() => {
          resultData.result = true;
          resultData.error = null;
          commit("setError", null);
        })
        .catch(e => {
          let response = e.response;
          if (response.status === 422) {
            commit("setError", response.data.detail);
          }
          resultData.result = false;
          if (e.response.data.detail?.category_type_id) {
            resultData.error = e.response.data.detail?.category_type_id[0];
          } else {
            resultData.error = response.data.detail;
          }
        })
        .finally(() => {
          commit("setChangesLoading", false);
        });
      return resultData;
    },
    async apiDeleteCategory({ commit }, id) {
      let result = { success: false, message: null };
      await Vue.axios
        .delete(`/api/admin/category/${id}`)
        .then(res => res.data)
        .then(data => {
          result.success = true;
          result.message = data.message;
        });
      return result;
    },
    async apiGetCategoryTypes({ commit }) {
      let result = false;
      await Vue.axios
        .get(`/api/admin/category-types`)
        .then(res => res.data)
        .then(data => {
          result = true;
          commit("setCategoryTypes", data.response);
        });
      return result;
    },
  },
};

