import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);
import auth from "./modules/auth";
import home from './modules/public/home'
import user from "./modules/private/user";
import general from "./modules/private/general";
import artworks from "./modules/private/artworks";
import artists from "./modules/private/artists";
import tags from "./modules/private/tags";
import pages from "./modules/private/pages";
import filters from "./modules/private/filters";
import categories from "./modules/private/categories";
import editorials from "./modules/private/editorials";
import blogs from "./modules/private/blogs";
import books from "./modules/private/books";
import viewingRooms from "./modules/private/viewingRooms";

import publicCategories from "./modules/public/categories";
import publickArtwork from "./modules/public/artwork";
import publicArtists from "./modules/public/artists";
import publicBlogs from "./modules/public/blogs";
import publicLibrary from "./modules/public/library";
import publicEditorials from "./modules/public/editorials";
import publicSearch from "./modules/public/search";
import publicImageResizer from "./modules/public/imageResizer";
import publicSubscribe from "./modules/public/subscribe";
import publicViewingRooms from "./modules/public/viewingRooms";

export default new Vuex.Store({
  namespaced: true,
  state: {
    breadcrumbs: [],
  },
  getters: {
    getBreadcrumbs(state) {
      return state.breadcrumbs;
    },
  },
  mutations: {
    setBreadcrumbs(state, breadcrumbs) {
      state.breadcrumbs = breadcrumbs;
    },
  },
  actions: {},
  modules: {
    general,
    auth,
    home,
    user,
    artworks,
    artists,
    tags,
    pages,
    filters,
    categories,
    editorials,
    blogs,
    books,
    viewingRooms,

    publicCategories,
    publickArtwork,
    publicArtists,
    publicBlogs,
    publicLibrary,
    publicEditorials,
    publicSearch,
    publicImageResizer,
    publicSubscribe,
    publicViewingRooms,
  },
});
