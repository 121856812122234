<template>
  <footer class="footer" v-if="footer">
    <div class="footer__block-text">
      <a class="footer__text" href="mailto:info@grottahouse.com">info@grottahouse.com</a>
      <router-link class="footer__text" to="/terms-and-conditions">Terms & Conditions</router-link>
      <router-link class="footer__text" to="/privacy-policy">Privacy Policy</router-link>
      <a class="footer__text" href="https://www.collekt.art/" target="_blank">Powered by Collekt</a>
    </div>
    <Copyright class="footer__copyright" :hiddenText="false">2022 Grotta House</Copyright>
    <div class="footer__social">
      <a class="footer__social-link" href="https://www.instagram.com/grottahouse/" target="_blank">
        <IconInstagram class="footer__social-icon" />
      </a>
      <a class="footer__social-link" href="https://www.facebook.com/grottahouse/" target="_blank">
        <IconFacebook class="footer__social-icon" />
      </a>
      <a class="footer__social-link" href="https://www.twitter.com/GrottaHouse/" target="_blank">
        <IconTwitter class="footer__social-icon" />
      </a>
    </div>
  </footer>
</template>

<script>
import Copyright from "@/components/Copyright.vue";
import IconYoutube from "@/components/icons/IconYoutube.vue";
import IconTwitter from "@/components/icons/IconTwitter.vue";
import IconFacebook from "@/components/icons/IconFacebook.vue";
import IconInstagram from "@/components/icons/IconInstagram.vue";

export default {
  components: {
    Copyright,
    IconYoutube,
    IconTwitter,
    IconFacebook,
    IconInstagram,
  },
  props: {
    footer: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.footer {
  position: relative;
  width: 100%;
  padding: 20px 50px;
  background-color: $lightBlack;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  @media (max-width: $xxs) {
    padding: 20px 25px 70px;
  }
  &__copyright {
    @media (max-width: $xxs) {
      width: auto;
      font-size: 2.8rem;
    }
    @media (max-width: $xxxs) {
      font-size: 2.5rem;
    }
    & ::v-deep .copyright_author {
      @media (max-width: $xxs) {
        font-size: 1.5rem;
      }
      @media (max-width: $xxxs) {
        padding-left: 5px;
        font-size: 1.4rem;
      }
    }
  }
  &__block-text {
    position: absolute;
    left: 48%;
    transform: translate(-50%, 0);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    @media (max-width: $sm) {
      position: relative;
    }
  }
  &__text {
    font-family: $fontOpenSans;
    color: $white;
    font-size: 1.6rem;
    letter-spacing: -0.3px;
    line-height: 2.75;
    text-decoration: none;
    margin: 0 40px;
    @media (max-width: $xl) {
      margin: 0 30px;
    }
    @media (max-width: $lg) {
      margin: 0 20px;
    }
    @media (max-width: $md) {
      margin: 0 30px;
    }
    @media (max-width: $xs) {
      margin: 0 40px;
    }
  }
  &__social {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 44px;
    &-link {
      margin-left: 30px;
      @media (max-width: $xxs) {
        margin-left: 25px;
      }
      @media (max-width: $xxxs) {
        margin-left: 20px;
      }
      &:first-of-type {
        margin-left: 0;
      }
    }
    &-icon {
      width: 30px;
      height: 25px;
      @media (max-width: $xxs) {
        width: 25px;
        height: 20px;
      }
    }
  }
}

@supports (-webkit-touch-callout: none) {
  .footer {
    padding-bottom: 90px;
  }
}
</style>
