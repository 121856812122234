<template>
  <!-- <svg :width="width" :height="height" :viewBox="viewBox">
    <path
      :fill="color"
      fill-rule="evenodd"
      d="M30 2.84400703c-1.12910027.48371214-2.32280862.8056705-3.54472141.95605656 1.28885893-.75122226 2.25179738-1.94293676 2.70527859-3.34800184-1.20486642.70530904-2.5243014 1.20082138-3.90029326 1.4647508C23.53558341.10357415 20.86054754-.48888007 18.5129111.42244091c-2.34763644.911321-3.89149695 3.14149422-3.89414277 5.62525755-.00534592.4644187.0426119.92794629.14296187 1.38177231C9.81336705 7.1930895 5.20173334 4.89287551 2.08211144 1.10506642.4375099 3.87009856 1.26668618 7.41748519 3.97360704 9.19727348c-.97449697-.02495529-1.92866073-.27983216-2.78225806-.7431987v.0649397c.00374483 2.88226505 2.06232837 5.36624991 4.93035191 5.94919726-.5265263.13516419-1.06877233.2018738-1.61290323.19842683-.39109051.00674402-.78181353-.02711558-1.16568915-.1010173.82018195 2.45837417 3.1197554 4.1456956 5.74780059 4.21747217-2.17723913 1.6747029-4.86134044 2.58405207-7.62463343 2.58315659C.97626427 21.36771206.48661162 21.34000197 0 21.28327154c2.81254779 1.78346142 6.08973847 2.72702295 9.43548387 2.71664374 11.3159824 0 17.5036657-9.23225941 17.5036657-17.2306647 0-.26697427 0-.52673305-.02199415-.78288405C28.13016118 5.13188927 29.17483455 4.06704826 30 2.84400703z"
    />
  </svg> -->
  <svg :width="width" :height="height" viewBox="0 0 24 24">
    <g>
      <path
        :fill="color"
        d="M18.244 2.25h3.308l-7.227 8.26 8.502 11.24H16.17l-5.214-6.817L4.99 21.75H1.68l7.73-8.835L1.254 2.25H8.08l4.713 6.231zm-1.161 17.52h1.833L7.084 4.126H5.117z"
      ></path>
    </g>
  </svg>
</template>
<script>
export default {
  props: {
    viewBox: { type: String, default: "0 0 30 24" },
    width: { type: String, default: "30" },
    height: { type: String, default: "24" },
    color: { type: String, default: "#fff" },
  },
};
</script>

