import Vue from "vue";
import router from "@/router";

export default {
  namespaced: true,
  state: {
    artwork: {},
  },
  getters: {
    getArtwork(state) {
      return state.artwork;
    },
  },
  mutations: {
    setArtwork(state, data) {
      if (data.associated?.books) {
        data.associated.books.map(book => {
          switch (book.title) {
            case "The Grotta House":
              book.slug = "the-grotta-house-by-richard-meier";
              break;

            case "The Grotta Home":
              book.slug = "the-grotta-home-by-richard-meier";
              break;
          }
        });
      }
      state.artwork = data;
    },
  },
  actions: {
    async apiGetArtwork({ commit, dispatch }, { categoryName, artworkSlug }) {
      let images = [];
      let search_image = [];
      let artwork = {};
      const response = await Vue.axios.get(`/api/public/categories/${categoryName}/${artworkSlug}`);
      if (response?.data.response?.id) {
        artwork = response.data.response;
        if (artwork.associated) {
          artwork.associated.editorials = artwork.associated.editorials ?? [];
          artwork.associated.books = artwork.associated.books ?? [];
          artwork.associated.blogs = artwork.associated.blogs ?? [];
          artwork.associated.tags = artwork.associated.tags ?? [];
          artwork.associated.related = artwork.associated.related ?? [];

          artwork.associated.related.forEach(art => {
            search_image.push({
              image: art.search_image,
              width: "425",
              height: "425",
            });
          });
        }

        artwork.images.forEach(image => {
          images.push({
            image: image,
            width: "700",
            height: "700",
          });
        });

        [images, search_image] = await Promise.all([
          dispatch("publicImageResizer/apiImageResizer", images, { root: true }),
          dispatch("publicImageResizer/apiImageResizer", search_image, { root: true }),
        ]);

        if (artwork.associated) {
          artwork.associated.related.forEach((art, index) => {
            art.search_image = search_image[index];
          });
        }

        artwork.originalImages = [...artwork.images];
        artwork.image = images[0];
        artwork.images = images;
        commit("setArtwork", artwork);
      } else router.push("/404");
    },
  },
};
