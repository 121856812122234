import Vue from "vue";
export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    async apiSubscribe({}, data) {
      let result = false;
      await Vue.axios
        .post("/api/public/subscribe", data)
        .then(response => {
          if (response?.data) {
            result = response.data;
          }
        })
        .catch(e => {
          let response = e.response;
          if (response.status === 422) {
            console.log(response.data.detail);
          }
        });
      return result;
    },
  },
};
