import Vue from "vue";
export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    async apiImageResizer({ commit }, images) {
      let resizeImages = [];
      await Vue.axios.post("/api/public/image-resizer", { images }).then(response => {
        resizeImages = response?.data.response ?? [];
      });
      return resizeImages;
    },
  },
};
