import Vue from "vue";

export default {
  namespaced: true,
  state: {
    categoryName: "",
    categoryType: {},
    categoriesList: {
      main_categories: [],
      sub_categories: [],
    },
    categoryArtworks: [],
    moreCategories: [],
    artistsList: [],
    hasMorePages: false,
    categoryTypes: [],
  },
  getters: {
    getCategoryName(state) {
      return state.categoryName;
    },

    getCategoryType(state) {
      return state.categoryType;
    },

    getCategoriesList(state) {
      return state.categoriesList;
    },

    getMenuCategoriesList(state) {
      // const showCategories = [
      //   "Baskets",
      //   "Bowls",
      //   "Figurative",
      //   "Graphic Design",
      //   "Tabletop",
      //   "Teapot",
      // ];
      // let menuCategories = state.categoriesList.filter(category => {
      //   for (const categoryName of showCategories) {
      //     if (category.name.toLowerCase() === categoryName.toLowerCase()) return true;
      //   }
      //   return false;
      // });
      // menuCategories = menuCategories.sort((a, b) => {
      //   return a.name.localeCompare(b.name);
      // });
      // return menuCategories;
      let menuCategories = state.categoriesList.main_categories;
      menuCategories = menuCategories.sort((a, b) => {
        return a.name.localeCompare(b.name);
      });
      return menuCategories;
    },
    // getMenuMediaList(state) {
    //   const showCategories = ["Basketry", "Ceramics", "Fiber", "Furniture", "Jewelry", "Wood"];
    //   let menuCategories = state.categoriesList.filter(category => {
    //     for (const categoryName of showCategories) {
    //       if (category.name.toLowerCase() === categoryName.toLowerCase()) return true;
    //     }
    //     return false;
    //   });
    //   menuCategories = menuCategories.sort((a, b) => {
    //     return a.name.localeCompare(b.name);
    //   });
    //   return menuCategories;
    // },
    getCategoryArtworks(state) {
      return state.categoryArtworks;
    },

    getMoreCategories(state) {
      return state.moreCategories;
    },

    getHasMorePages(state) {
      return state.hasMorePages;
    },
    getArtistsList(state) {
      return state.artistsList;
    },
    getCategoryTypes(state) {
      return state.categoryTypes;
    },
  },
  mutations: {
    setCategoryName(state, payload) {
      state.categoryName = payload ?? "Collection";
    },

    setCategoryType(state, payload) {
      state.categoryType = payload;
    },

    setCategoriesList(state, payload) {
      //state.categoriesList = payload.sort((a, b) => (a.name > b.name ? 1 : -1));
      state.categoriesList = {
        main_categories:
          payload.main_categories.length > 0
            ? payload.main_categories.sort((a, b) => (a.name > b.name ? 1 : -1))
            : [],
        sub_categories:
          payload.sub_categories.length > 0
            ? payload.sub_categories.sort((a, b) => (a.name > b.name ? 1 : -1))
            : [],
      };
    },

    setCategoryArtworks(state, { artworks, page, mobile = false }) {
      if (mobile && page > 1) {
        state.categoryArtworks = state.categoryArtworks.concat(artworks);
      } else state.categoryArtworks = artworks;
    },

    setMoreCategories(state, payload) {
      const moreCategories = payload.sort((a, b) => {
        return a.name.localeCompare(b.name);
      });
      state.moreCategories = moreCategories;
    },

    setHasMorePages(state, payload) {
      state.hasMorePages = payload;
    },
    setArtistsList(state, payload) {
      state.artistsList = payload;
    },
    setCategoryTypes(state, payload) {
      state.categoryTypes = payload;
    },
  },
  actions: {
    async apiGetCategoriesList({ commit, dispatch }, options = {}) {
      let { type = "" } = options;
      let main_categories_image = [];
      let sub_categories_image = [];
      let categories = {
        main_categories: [],
        sub_categories: [],
      };
      let queryStr = "";
      if (type) {
        queryStr = `?type=${type}`;
      }
      await Vue.axios.get(`/api/public/categories${queryStr}`).then(response => {
        categories = response?.data.response ?? {};
        categories.main_categories.map(categorie => {
          main_categories_image.push({
            image: categorie.image,
            width: `${window.innerWidth}`,
            height: `${window.innerHeight}`,
          });
        });
        categories.sub_categories.map(categorie => {
          sub_categories_image.push({
            image: categorie.image,
            width: `${window.innerWidth}`,
            height: `${window.innerHeight}`,
          });
        });
      });

      main_categories_image = await dispatch(
        "publicImageResizer/apiImageResizer",
        main_categories_image,
        { root: true }
      );
      sub_categories_image = await dispatch(
        "publicImageResizer/apiImageResizer",
        sub_categories_image,
        { root: true }
      );

      categories.main_categories.map((categorie, index) => {
        categorie.image = main_categories_image[index];
      });
      categories.sub_categories.map((categorie, index) => {
        categorie.image = sub_categories_image[index];
      });
      commit("setCategoriesList", categories);
    },

    async apiGetSpecificCategory({ commit, dispatch }, { slug, page, mobile = false }) {
      let success = false;
      let categoryName = "";
      let categoryType = {};
      let images = [];
      let categories = [];
      let artworks = [];
      let artistsList = [];
      let hasMorePages = null;
      let url = `/api/public/categories/${slug}?page=${page}`;
      if (slug === "collection") url = `/api/public/collection?page=${page}`;
      await Vue.axios.get(url).then(response => {
        success = true;
        categoryName = response?.data?.response?.category_name;
        categoryType = response?.data?.response?.category_type ?? {};
        artworks = response?.data?.response?.artworks ?? [];
        categories = response?.data?.response?.categories ?? [];
        hasMorePages = response?.data?.hasMorePages;
        artistsList = response?.data?.response?.artists ?? [];
        artworks.map(art => {
          let width = `${Math.round(window.innerWidth * 0.3)}`;
          let height = `${Math.round(window.innerWidth * 0.3)}`;
          if (window.innerWidth <= 425) {
            width = `${window.innerWidth}`;
            height = 372;
          }
          images.push({
            image: art.image,
            width,
            height,
          });
        });
        categories.map(categorie => {
          let width = `${Math.round(window.innerWidth * 0.2)}`;
          let height = 300;
          if (window.innerWidth <= 425) {
            width = `${window.innerWidth}`;
          }
          images.push({
            image: categorie.image,
            width,
            height,
          });
        });
      });
      images = await dispatch("publicImageResizer/apiImageResizer", images, { root: true });

      artworks.map((art, index) => {
        art.image = images[index];
      });
      categories.map((categorie, index) => {
        categorie.image = images[index + artworks.length];
      });

      commit("setCategoryName", categoryName);
      commit("setCategoryType", categoryType);
      commit("setCategoryArtworks", { artworks, page, mobile });
      commit("setMoreCategories", categories);
      commit("setHasMorePages", hasMorePages);
      commit("setArtistsList", artistsList);
      return success;
    },
    async apiGetCategoryTypes({ commit }) {
      let result = false;
      await Vue.axios
        .get(`/api/public/category-types`)
        .then(res => res.data)
        .then(data => {
          result = true;
          commit("setCategoryTypes", data.response);
        });
      return result;
    },
  },
};

