import Vue from "vue";
export default {
  namespaced: true,
  state: {
    loading: false,
    blogs: [],
    blogsHeaders: [
      {
        key: "title",
        title: "Title",
        sortable: true,
        className: "capitalize",
      },
      {
        key: "author",
        title: "Author",
        sortable: true,
        className: "md",
      },
      {
        key: "tags",
        title: "Tags",
        sortable: true,
        className: "md",
        variables: "tag",
      },
      {
        key: "status",
        title: "Status",
        sortable: true,
        className: "md",
        variables: "status",
      },
      {
        key: "publish_date",
        title: "Date Published ",
        sortable: true,
        className: "md",
      },
      {
        key: "edit",
        title: "Edit",
        sortable: false,
        className: "xsm",
        variables: "icon",
        icon: "IconEdit",
      },
      {
        key: "delete",
        title: "Delete",
        sortable: false,
        className: "xsm",
        variables: "icon",
        icon: "IconDelete",
      },
    ],
    searchedBlogs: [],
    blog: null,
    changesLoading: false,
    hasMorePages: false,
    page: 1,
    serverError: {
      slug: null,
    },
  },
  getters: {
    getLoading(state) {
      return state.loading;
    },
    getBlogsHeaders(state) {
      return state.blogsHeaders;
    },
    getBlogs(state) {
      return state.blogs;
    },
    getSearchedBlogs(state) {
      return state.searchedBlogs;
    },
    getBlog(state) {
      return state.blog;
    },
    getChangesLoading(state) {
      return state.changesLoading;
    },
    getHasMorePages(state) {
      return state.hasMorePages;
    },
    getServerError(state) {
      return state.serverError;
    },
  },
  mutations: {
    setLoading(state, payload) {
      state.loading = payload;
    },
    setBlogs(state, payload) {
      let blogs = payload.map(el => {
        let tags = {
          main: "",
          additional: [],
        };
        if (el.tags.length > 0) {
          tags.main = el.tags[0].title;
          tags.additional = el.tags.slice(1);
        }
        return {
          ...el,
          tags: tags,
          publish_date: el.publish_date
            ? Vue.moment(el.publish_date, "YYYY-MM-DD").format("MMM DD YYYY")
            : "-",
        };
      });
      if (state.page > 1) {
        state.blogs = state.blogs.concat(blogs);
      } else {
        state.blogs = blogs;
      }
    },
    setSearchedBlogs(state, payload) {
      state.searchedBlogs = payload;
    },
    initialBlog(state) {
      state.blog = null;
    },
    setBlog(state, payload) {
      state.blog = payload;
    },
    setChangesLoading(state, payload) {
      state.changesLoading = payload;
    },
    setPage(state, payload) {
      state.page = payload;
    },
    setHasMorePages(state, payload) {
      state.hasMorePages = payload;
    },
    setError(state, payload) {
      let serverError = state.serverError;
      if (payload != null) {
        for (let i in payload) {
          serverError[i] = payload[i][0];
        }
        state.serverError = serverError;
      } else
        state.serverError = {
          slug: null,
        };
    },
  },
  actions: {
    async apiGetBlogs({ commit }, { sort = "title", order = "asc", page = "1", status = "" }) {
      let success = false;
      commit("setLoading", true);
      commit("setHasMorePages", false);
      let query = {
        sort,
        order,
        page,
        status,
      };
      let queryStr = "";
      for (let key in query) {
        if (query[key] != "") {
          queryStr += `${key}=${query[key]}&`;
        }
      }
      await Vue.axios
        .get(`/api/admin/blogs?${queryStr}`)
        .then(res => res.data)
        .then(data => {
          success = true;
          commit("setPage", page);
          commit("setHasMorePages", data.hasMorePages);
          commit("setBlogs", data.response);
        })
        .finally(() => {
          commit("setLoading", false);
        });
      return success;
    },
    async apiGetSearchedBlogs({ commit }, { search = "", excludeIdx }) {
      let success = false,
        limit = "all";
      commit("setChangesLoading", true);

      let excludeIdxString = "";
      if (excludeIdx.length > 0) {
        excludeIdxString = excludeIdx
          .map(el => {
            return el.id;
          })
          .join(",");
      }

      await Vue.axios
        .get(`/api/admin/blogs?limit=${limit}&search=${search}&exclude_ids=${excludeIdxString}`)
        .then(res => res.data)
        .then(data => {
          success = true;
          commit("setSearchedBlogs", data.response);
        })
        .finally(() => {
          commit("setChangesLoading", false);
        });
      return success;
    },
    async apiPostBlog({ commit }, payload) {
      let result = false;
      let data = {
        ...payload,
        is_published: payload.is_published != null ? payload.is_published : "0",
      };
      commit("setChangesLoading", true);
      await Vue.axios
        .post("/api/admin/blog", data)
        .then(res => res.data)
        .then(data => {
          result = true;
          commit("setError", null);
        })
        .catch(e => {
          let response = e.response;
          if (response.status === 422) {
            commit("setError", response.data.detail);
          }
        })
        .finally(() => {
          commit("setChangesLoading", false);
        });
      return result;
    },
    async apiGetBlog({ commit }, id) {
      let result = false;
      await Vue.axios
        .get(`/api/admin/blog/${id}`)
        .then(res => res.data)
        .then(data => {
          result = true;
          commit("setBlog", data.response);
        });
      return result;
    },
    async apiPutBlog({ commit }, payload) {
      commit("setChangesLoading", true);
      let result = false;
      await Vue.axios
        .put(`/api/admin/blog/${payload.id}`, payload)
        .then(res => res.data)
        .then(data => {
          result = true;
          commit("setError", null);
        })
        .catch(e => {
          let response = e.response;
          if (response.status === 422) {
            commit("setError", response.data.detail);
          }
        })
        .finally(() => {
          commit("setChangesLoading", false);
        });
      return result;
    },
    async apiDeleteBlog({ commit }, id) {
      let result = { success: false, message: null };
      await Vue.axios
        .delete(`/api/admin/blog/${id}`)
        .then(res => res.data)
        .then(data => {
          result.success = true;
          result.message = data.message;
        });
      return result;
    },
  },
};
