export default [
  { path: "/explore", redirect: "/" },
  { path: "/the-grotta-house", redirect: "/" },
  { path: "/interior", redirect: "/interiors/1" },
  { path: "/exterior", redirect: "/exteriors/1" },
  { path: "/news", redirect: "/library" },
  {
    path: "/news/2021/8/30/lives-well-lived-sandra-grotta",
    redirect: "/library/blog/Lives-Well-Lived-Sandra-Grotta",
  },
  {
    path: "/news/book-launch-the-grotta-home-by-richard-meier",
    redirect: "/library/blog/The-Grotta-Home-by-Richard-Meier-A-Marriage-of-Architecture-and-Craft",
  },
  {
    path: "/news/2017/4/5/the-grotta-house",
    redirect: "/library/blog/Welcome-to-The-Grotta-House",
  },
  { path: "/contact", redirect: "/" },
  { path: "/history", redirect: "/about/the-history" },
  { path: "/about-the-grottas", redirect: "/about" },
];
