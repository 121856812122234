import Vue from "vue";
import router from "@/router";

export default {
  namespaced: true,
  state: {
    editorials: [],
    hasMorePages: false,
    editorial: {},
    editorialTypes: [],
  },
  getters: {
    getEditorials(state) {
      return state.editorials;
    },
    getHasMorePages(state) {
      return state.hasMorePages;
    },
    getEditorial(state) {
      return state.editorial;
    },
    getEditorialTypes(state) {
      return state.editorialTypes;
    },
  },
  mutations: {
    setEditorials(state, { data, page }) {
      if (page > 1) state.editorials = state.editorials.concat(data);
      else state.editorials = data;
    },
    setHasMorePages(state, data) {
      state.hasMorePages = data;
    },
    setEditorial(state, data) {
      state.editorial = data;
    },
    setEditorialTypes(state, data) {
      state.editorialTypes = data;
    },
  },
  actions: {
    async apiGetEditorials({ commit }, { page = "", type = "" }) {
      let query = {
        page,
        type,
      };
      let queryStr = "";
      for (let key in query) {
        if (query[key] != "") {
          queryStr += `${key}=${query[key]}&`;
        }
      }
      await Vue.axios.get(`/api/public/editorials?${queryStr}`).then(response => {
        if (response?.data?.response)
          commit("setEditorials", { data: response.data.response.data, page });
        commit("setHasMorePages", response.data.response.hasMorePages);
      });
    },
    async apiGetEditorial({ commit }, slug) {
      await Vue.axios.get(`/api/public/editorials/${slug}`).then(response => {
        if (response?.data?.response) {
          commit("setEditorial", response.data.response);
        } else router.push("/404");
      });
    },
    async apiGetEditorialTypes({ commit }) {
      await Vue.axios.get(`/api/public/editorial-types`).then(response => {
        commit("setEditorialTypes", response.data.response);
      });
    },
  },
};
