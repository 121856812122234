const Library = () => import("@/views/library/Library.vue");
const BookLandingPage = () => import("@/views/library/books/BookLandingPage.vue");
const TheGrottaHomeBook = () => import("@/views/library/books/TheGrottaHomeBook.vue");
const TheGrottaHouseBook = () => import("@/views/library/books/TheGrottaHouseBook.vue");
const Blog = () => import("@/views/library/blog/Blog.vue");
const Blogs = () => import("@/views/library/blog/Blogs.vue");
const Editorial = () => import("@/views/library/editorial/Editorial.vue");
const Editorials = () => import("@/views/library/editorial/Editorials.vue");
const Videos = () => import("@/views/library/Videos.vue");

export default [
  {
    path: "/library",
    component: Library,
    meta: {
      layout: "base",
      footer: true,
    },
  },
  {
    path: "/library/books",
    name: "BookLandingPage",
    component: BookLandingPage,
    meta: {
      layout: "base",
      footer: true,
    },
  },
  {
    path: "/library/the-grotta-home-by-richard-meier",
    name: "TheGrottaHomeBook",
    component: TheGrottaHomeBook,
    meta: {
      layout: "base",
      footer: true,
    },
  },
  {
    path: "/library/the-grotta-house-by-richard-meier",
    name: "TheGrottaHouseBook",
    component: TheGrottaHouseBook,
    meta: {
      layout: "base",
      footer: true,
    },
  },
  {
    path: "/library/blog",
    name: "Blogs",
    component: Blogs,
    meta: {
      layout: "base",
      footer: true,
    },
  },
  {
    path: "/library/blog/:blogSlug",
    name: "SpecificBlog",
    component: Blog,
    meta: {
      layout: "base",
      footer: true,
    },
  },
  {
    path: "/library/publications",
    name: "PublicEditorials",
    component: Editorials,
    meta: {
      layout: "base",
      footer: true,
    },
  },
  {
    path: "/library/publications/:editorialSlug",
    name: "Editorial",
    component: Editorial,
    meta: {
      layout: "base",
      footer: true,
    },
  },
  {
    path: "/library/videos",
    component: Videos,
    meta: {
      layout: "base",
      footer: true,
    },
  },
];
