import Vue from 'vue'
export default {
    namespaced: true,
    state: {
        filters: [],
        selectedFilters: [],
    },
    getters: {
        getFilters(state) { return state.filters },
        getSelectedFilters(state) { return state.selectedFilters },
    },
    mutations: {
        createFilters(state, payload) {
            state.filters = payload.map(el => {
                return {
                    ...el,
                    key: el.group.toLowerCase(),
                    open: false,
                    items: el.items.map(el => {
                        return {
                            ...el,
                            selected: false
                        }
                    })
                }
            })
        },
        createSelectedFilters(state, payload) {
            let selectedFilters = [];
            payload.forEach(item => {
                selectedFilters.push({ key: item.group.toLowerCase(), items: [] });
            });
            state.selectedFilters = selectedFilters
        },
        updateFilters(state, payload) {
            let { key, filter } = payload
            for (let el of state.filters) {
                if (el.key === key) {
                    el.items.forEach(element => {
                        if (element.id === filter.id) {
                            element.selected = !element.selected
                        }
                    });
                    break;
                }
            }
        },
        updateSelectedFilters(state, payload) {
            let selectedFilters = state.selectedFilters;
            let idx = 0
            for (let el of state.filters) {
                let items = []
                el.items.forEach(element => {
                    if (element.selected) {
                        items.push(element)
                    }
                });
                selectedFilters[idx].items = items
                idx++
            }
            state.selectedFilters = selectedFilters
        },

        clear(state) {
            for (let el of state.filters) {
                el.items.forEach(element => {
                    element.selected = false
                });
            }
        }
    },
    actions: {
        async apiGetFiltersBlock({ commit }, { list, type }) {
            await Vue.axios.get(`/api/admin/filters?list=${list}&type=${type}`)
                .then((res) => res.data)
                .then((data) => {
                    commit('createFilters', data.response)
                    commit('createSelectedFilters', data.response)
                })
        },
    }
}