const General = () => import("@/views/interiors/General.vue");
const Interiors = () => import("@/views/interiors/Interiors.vue");

export default [
  {
    path: "/interiors",
    redirect: "/interiors/1",
    component: General,
    children: [
      {
        path: ":id",
        name: "Interiors",
        component: Interiors,
        meta: {
          layout: "base",
          footer: false,
        },
      },
    ],
  },
];
