<template>
  <svg :width="width" :height="height" :viewBox="viewBox">
    <g :fill="color">
      <rect width="32" height="4" x="0" y="0" fill="rgb(0,0,0)" rx="0" ry="0" />
      <rect width="18" height="4" x="14" y="7" fill="rgb(0,0,0)" rx="0" ry="0" />
    </g>
  </svg>
</template>
<script>
export default {
  props: {
    viewBox: { type: String, default: "0 0 32 11" },
    width: { type: String, default: "32" },
    height: { type: String, default: "11" },
    color: { type: String },
  },
};
</script>