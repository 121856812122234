<template>
  <svg :width="width" :height="height" :viewBox="viewBox" fill="none">
    <g clip-path="url(#clip0_11_15)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M17.3425 0.171661C17.1137 -0.0572205 16.7426 -0.0572205 16.5139 0.171661L11.9981 4.68753H1.75782C0.788578 4.68753 0 5.4761 0 6.44534V14.6484C0 15.6178 0.788578 16.4062 1.75782 16.4062H2.38283V19.414C2.38283 19.6522 2.52702 19.8668 2.74751 19.9566C2.97121 20.0479 3.22344 19.9916 3.38762 19.8239L6.73084 16.4062H13.5548C14.524 16.4062 15.3126 15.6178 15.3126 14.6484V8.00206L19.8285 3.48619C20.0572 3.25731 20.0572 2.88637 19.8285 2.65749L17.3425 0.171661ZM16.0995 2.24321L17.7568 3.90048L18.5855 3.07193L16.9282 1.41466L16.0995 2.24321ZM11.9562 9.70099L10.299 8.04372L15.2709 3.07191L16.9282 4.72919L11.9562 9.70099V9.70099ZM14.1407 14.6484C14.1407 14.9715 13.8778 15.2343 13.5548 15.2343H6.48441C6.32679 15.2343 6.17573 15.2979 6.06556 15.4106L3.55471 17.9772V15.8203C3.55471 15.4968 3.29241 15.2343 2.96877 15.2343H1.75782C1.43479 15.2343 1.17188 14.9715 1.17188 14.6484V6.44534C1.17188 6.12216 1.43479 5.85941 1.75782 5.85941H10.826L9.05599 7.62944C9.00716 7.67827 8.95879 7.74556 8.9266 7.82521L7.30473 11.7188H4.14065C3.81701 11.7188 3.55471 11.9811 3.55471 12.3047C3.55471 12.6283 3.81701 12.8902 4.14065 12.8902H7.69551C7.77089 12.8902 7.85176 12.8748 7.92577 12.8431L12.1748 11.0734C12.2456 11.0457 12.317 10.9977 12.3707 10.944L14.1407 9.17393V14.6484V14.6484ZM8.78331 11.2166L10.9232 10.3252L9.67474 9.07674L8.78331 11.2166Z"
      />
    </g>
    <defs>
      <clipPath id="clip0_11_15">
        <rect :width="width" :height="height" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>
<script>
export default {
  props: {
    viewBox: { type: String, default: "0 0 20 20" },
    width: { type: String, default: "20" },
    height: { type: String, default: "20" },
    color: { type: String },
  },
};
</script>