import Vue from "vue";
import router from "@/router";

function artworkSize(attributes) {
  let text = "";
  if (attributes.height) text += `${attributes.height}”`;
  if (attributes.width) {
    if (attributes.height) text += " x ";
    text += `${attributes.width}”`;
  }
  if (attributes.depth) {
    if (attributes.width || attributes.height) text += " x ";
    text += `${attributes.depth}”`;
  }
  return text ? text : null;
}

export default {
  namespaced: true,
  state: {
    viewingRoom: {},
    viewingRoomArtwork: {},
  },
  getters: {
    getViewingRoom(state) {
      return state.viewingRoom;
    },
    getViewingRoomArtwork(state) {
      return state.viewingRoomArtwork;
    },
  },
  mutations: {
    setViewingRoom(state, data) {
      state.viewingRoom = data;
    },
    setViewingRoomArtwork(state, data) {
      if (data.artwork.attributes) {
        data.artwork.attributes = {
          size: artworkSize(data.artwork.attributes),
          weight: data.artwork.attributes.weight ? `${data.artwork.attributes.weight}lbs` : null,
          condition: data.artwork.attributes.condition,
        };
        let isNotNull = false;
        for (let key in data.artwork.attributes) {
          if (data.artwork.attributes[key] !== null) {
            isNotNull = true;
            break;
          }
        }
        if (!isNotNull) data.artwork.attributes = null;
      }
      if (data.artwork.details) {
        let isNotNull = false;
        for (let key in data.artwork.details) {
          if (data.artwork.details[key] !== null) {
            isNotNull = true;
            break;
          }
        }
        if (!isNotNull) data.artwork.details = null;
      }
      state.viewingRoomArtwork = data;
    },
  },
  actions: {
    async apiGetViewingRoom({ commit, dispatch }, { viewingRoomSlug }) {
      let images = [];
      let viewingRoom = {};
      await Vue.axios
        .get(`/api/public/viewing-room/${viewingRoomSlug}`)
        .then(response => {
          viewingRoom = response.data.response;
          viewingRoom.artworks.map(artwork => {
            images.push({
              image: artwork.search_image,
              width: "700",
              height: "700",
            });
          });
        })
        .catch(e => {
          let error = e.response;
          if (error.details.code === 422) router.push("/404");
        });
      images = await dispatch("publicImageResizer/apiImageResizer", images, { root: true });
      viewingRoom.artworks.map((artwork, index) => {
        artwork.search_image = images[index];
      });
      commit("setViewingRoom", viewingRoom);
    },
    async apiGetViewingRoomArtwork({ commit, dispatch }, { viewingRoomSlug, artworkSlug }) {
      let images = [];
      let viewingRoomArtwork = {};
      await Vue.axios
        .get(`/api/public/viewing-room/${viewingRoomSlug}/artwork/${artworkSlug}`)
        .then(response => {
          viewingRoomArtwork = response.data.response;
          viewingRoomArtwork.artwork.images.map(image => {
            images.push({
              image,
              width: `${window.innerWidth}`,
              height: `${window.innerWidth}`,
            });
          });
        })
        .catch(e => {
          let error = e.response;
          if (error.details.code === 422) router.push("/404");
        });
      images = await dispatch("publicImageResizer/apiImageResizer", images, { root: true });
      viewingRoomArtwork.artwork.images = images;
      commit("setViewingRoomArtwork", viewingRoomArtwork);
    },
  },
};
