import Vue from "vue";
export default {
  namespaced: true,
  state: {
    data: { title: "", subtext: "" },
  },
  getters: {
    getData(state) {
      return state.data;
    },
  },
  mutations: {
    setData(state, payload) {
      state.data = payload;
    },
  },
  actions: {
    async apiGetTextData({ commit }, slug) {
      await Vue.axios.get(`/api/public/page?slug=${slug}`).then(response => {
        commit("setData", response?.data.response ?? {});
      });
    },
  },
};

