const ChangePassword = () => import("@/views/auth/ChangePassword.vue");

export default [
  {
    path: "/change-password",
    name: "ChangePassword",
    component: ChangePassword,
    meta: {
      layout: "empty",
    },
  },
];
