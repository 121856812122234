import Vue from 'vue'
export default {
    namespaced: true,
    state: {},
    getters: {},
    mutations: {},
    actions: {
        async apiPostUploadImage({ commit }, payload) {
            const fd = new FormData();
            fd.append('files[]', payload)

            let image = false
            await Vue.axios.post("/api/admin/file-uploader", fd)
                .then((res) => res.data)
                .then((data) => {
                    image = data.response.valid[0]
                })
            return image
        },
        async apiDeleteUploadImage({ commit }, payload) {
            await Vue.axios.delete(`/api/admin/file-uploader`, {
                params: {
                    files: payload
                }
            })
                .then((res) => res.data)
                .then((data) => {
                })
        },
    }
}