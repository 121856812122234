<template>
  <svg :width="width" :height="height" :viewBox="viewBox">
    <g :fill="color">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M18.4569 17.246L24.6199 23.6819C25.1412 24.2264 25.124 25.0945 24.5822 25.618C24.3271 25.8644 23.9912 26 23.6371 26C23.2631 26 22.9139 25.8508 22.6544 25.5801L16.4446 19.0954C14.6814 20.3396 12.619 20.995 10.4548 20.995C4.69019 20.995 0 16.2857 0 10.4975C0 4.70927 4.69019 0 10.4548 0C16.2196 0 20.9098 4.70927 20.9098 10.4975C20.9098 12.9749 20.0415 15.3546 18.4569 17.246ZM18.1825 10.4975C18.1825 6.21908 14.7159 2.73847 10.455 2.73847C6.19385 2.73847 2.72742 6.21908 2.72742 10.4975C2.72742 14.7759 6.19385 18.2565 10.455 18.2565C14.7159 18.2565 18.1825 14.7759 18.1825 10.4975V10.4975Z"
      />
    </g>
  </svg>
</template>
<script>
export default {
  props: {
    viewBox: { type: String, default: "0 0 25 26" },
    width: { type: String, default: "25" },
    height: { type: String, default: "26" },
    color: { type: String },
  },
};
</script>