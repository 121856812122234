const Artists = () => import("@/views/artists/Artists.vue");
const ArtistProfile = () => import("@/views/artists/ArtistProfile.vue");

export default [
  {
    path: "/creators",
    name: "ArtistsPage",
    component: Artists,
    meta: {
      layout: "base",
      footer: true,
    },
  },
  {
    path: "/creators/:slug",
    name: "ArtistProfile",
    component: ArtistProfile,
    meta: {
      layout: "base",
      footer: true,
    },
  },
];
