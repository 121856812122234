<template>
  <svg :width="width" :height="height" :viewBox="viewBox">
    <g>
      <path
        :fill="color"
        fill-rule="evenodd"
        d="M17.87384033 0H8.12615967C5.9710083 0 3.90405273.82318115 2.38006592 2.2885437.85614014 3.75390625 0 5.741333 0 7.81362915v9.3727417c0 2.07229614.85614014 4.0597229 2.38006592 5.52508545C3.90405273 24.17681885 5.9710083 25 8.12615967 25h9.74768066c2.15515137 0 4.22210694-.82318115 5.74609375-2.2885437C25.14385986 21.24609375 26 19.258667 26 17.18637085v-9.3727417c0-2.07229614-.85614014-4.0597229-2.38006592-5.52508545C22.09594727.82318115 20.0289917 0 17.87384033 0zm5.68927002 17.18637085c-.00360107 3.0198059-2.54870605 5.4670105-5.68927002 5.4704895H8.12615967c-3.14056397-.003479-5.68566895-2.4506836-5.68927002-5.4704895v-9.3727417c.00360107-3.01977539 2.54870605-5.4670105 5.68927002-5.47045898h9.74768066c3.14056397.00344848 5.68566895 2.45068359 5.68927002 5.47045898v9.3727417z"
      />
      <path
        :fill="color"
        fill-rule="evenodd"
        d="M12.99993896 6.25149536c-3.58892822 0-6.49841308 2.7975769-6.49841308 6.24847412s2.90948486 6.2484436 6.49841308 6.2484436c3.58892823 0 6.4984131-2.79754638 6.4984131-6.2484436 0-3.45089721-2.90948487-6.24847412-6.4984131-6.24847412zm0 10.15368652c-2.24316406 0-4.06152343-1.7484436-4.06152343-3.9052124 0-2.15686035 1.81835937-3.90530395 4.06152343-3.90530395 2.243042 0 4.06140137 1.7484436 4.06140137 3.90530395-.00170898 2.15609741-1.8190918 3.90356446-4.06140137 3.9052124z"
      />
      <ellipse cx="19.987" cy="5.782" :fill="color" rx=".867" ry=".834" />
    </g>
  </svg>
</template>
<script>
export default {
  props: {
    viewBox: { type: String, default: "0 0 26 25" },
    width: { type: String, default: "26" },
    height: { type: String, default: "25" },
    color: { type: String, default: "#fff" },
  },
};
</script>
