import Vue from "vue";
import store from "./store";

import axios from "axios";
import VueAxios from "vue-axios";
Vue.use(VueAxios, axios);

import router from "./router";

axios.defaults.baseURL = process.env.VUE_APP_BACKEND_URL;

axios.interceptors.request.use(
  function (config) {
    const token = Vue.$cookies.get("token") || null;
    if (token != null) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (401 === error.response.status) {
      store.commit("setToken", {
        access_token: null,
        expires_in: null,
      });
      router.push("/login");
    } else if (404 === error.response.status) {
      router.push("/404");
    } else if (500 <= error.response.status) {
      Vue.swal({
        title: "Sorry, something went wrong!",
        confirmButtonText: "Ok",
      }).then(result => {});
    } else {
      return Promise.reject(error);
    }
  }
);
