import { extend, localize, configure } from "vee-validate";
import { min, max, required, email, confirmed, numeric } from "vee-validate/dist/rules";

import en from "vee-validate/dist/locale/en.json";

// Install rules
extend("required", {
  ...required,
  message: "This field is required",
});

extend("limitNumber", {
  validate: value => {
    return value * 1 < 21474836;
  },
  message: "Please enter value less then 21 474 836",
});

extend("idRequired", {
  validate: value => {
    console.log(value.id != null);
    return value.id != null;
  },
  message: "This field is required",
});

extend("email", {
  ...email,
  message: "Invalid email",
});
extend("number", {
  validate(value) {
    const regex = RegExp(/^[1-9][\.\d]*(,\d+)?$/);
    return regex.test(value);
  },
  message: "Accepts numbers and decimals",
});
extend("login_email", {
  ...email,
  message: "The Email you’ve entered is incorrect",
});
extend("verify_password", {
  message: "too weak",
  validate: value => {
    var strongRegex = new RegExp(
      "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!\"#$%&'()*+,-./:;<=>?@^_`{|}~[])(?=.{8,50})"
    );

    return strongRegex.test(value);
  },
});
extend("min", {
  validate(value, args) {
    let newArg = args ? args : 3;
    if (value.replace(/\s+/g, "").length >= newArg) {
      return true;
    }
    return `Min ${newArg} characters`;
  },
});
extend("max", {
  validate(value, args) {
    let newArg = args ? args : 200;
    if (value.length <= newArg) {
      return true;
    }
    return `Max ${newArg} characters`;
  },
});

extend("confirmed", {
  ...confirmed,
  message: "doesn't match",
});

extend("positive", value => {
  if (value >= 0) {
    return true;
  }
  return "This field must be a positive number";
});

extend("path", {
  validate: value => {
    const regex = RegExp(/^[a-zA-Z0-9-]+$/);
    return regex.test(value);
  },
  message: 'Invalid path. Only dashes, numbers and letters. Example "my-name"',
});

configure({
  classes: {
    valid: "is-valid",
    invalid: "is-invalid",
  },
});

// Install messages
localize({
  en,
});
