<template>
  <svg :width="width" :height="height" :viewBox="viewBox" fill="none">
    <g clip-path="url(#clip0_20_2)" fill="none" class="fill--transparent">
      <path
        d="M12.7611 0.649994H19.65C20.2019 0.649994 20.65 1.09808 20.65 1.64999V6.31666C20.65 6.86858 20.2019 7.31666 19.65 7.31666H12.7611C12.2092 7.31666 11.7611 6.86858 11.7611 6.31666V1.64999C11.7611 1.09808 12.2092 0.649994 12.7611 0.649994V0.649994Z"
        stroke="black"
        stroke-width="1.3"
      />
      <path
        d="M1.65 0.649994H8.53891C9.09082 0.649994 9.53891 1.09808 9.53891 1.64999V10.7611C9.53891 11.313 9.09082 11.7611 8.53891 11.7611H1.65C1.09809 11.7611 0.650002 11.313 0.650002 10.7611V1.64999C0.650002 1.09808 1.09809 0.649994 1.65 0.649994V0.649994Z"
        stroke="black"
        stroke-width="1.3"
      />
      <path
        d="M1.65 13.9833H8.53891C9.09082 13.9833 9.53891 14.4314 9.53891 14.9833V19.65C9.53891 20.2019 9.09082 20.65 8.53891 20.65H1.65C1.09809 20.65 0.650002 20.2019 0.650002 19.65V14.9833C0.650002 14.4314 1.09809 13.9833 1.65 13.9833V13.9833Z"
        stroke="black"
        stroke-width="1.3"
      />
      <path
        d="M12.7611 9.53889H19.65C20.2019 9.53889 20.65 9.98698 20.65 10.5389V19.65C20.65 20.2019 20.2019 20.65 19.65 20.65H12.7611C12.2092 20.65 11.7611 20.2019 11.7611 19.65V10.5389C11.7611 9.98698 12.2092 9.53889 12.7611 9.53889V9.53889Z"
        stroke="black"
        stroke-width="1.3"
      />
    </g>
    <defs>
      <clipPath id="clip0_20_2">
        <rect :width="width" :height="height" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>
<script>
export default {
  props: {
    viewBox: { type: String, default: "0 0 22 22" },
    width: { type: String, default: "22" },
    height: { type: String, default: "22" },
    color: { type: String },
  },
};
</script>