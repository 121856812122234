import Vue from "vue";
export default {
  namespaced: true,
  state: {
    loading: false,
    editorials: [],
    editorialsHeaders: [
      {
        key: "title",
        title: "Title",
        sortable: true,
        className: "capitalize",
      },
      {
        key: "author",
        title: "Author",
        sortable: true,
        className: "md",
      },
      {
        key: "status",
        title: "Status",
        sortable: true,
        className: "md",
        variables: "status",
      },
      {
        key: "publish_date",
        title: "Date Published ",
        sortable: true,
        className: "md",
      },
      {
        key: "indent",
        className: "indent",
      },
      {
        key: "reorder",
        title: "Reorder",
        sortable: false,
        className: "xsm",
        variables: "icon",
        icon: "IconReorder",
      },
      {
        key: "edit",
        title: "Edit",
        sortable: false,
        className: "xsm",
        variables: "icon",
        icon: "IconEdit",
      },
      {
        key: "delete",
        title: "Delete",
        sortable: false,
        className: "xsm",
        variables: "icon",
        icon: "IconDelete",
      },
    ],
    searchedEditorials: [],
    editorial: null,
    changesLoading: false,
    hasMorePages: false,
    page: 1,
    serverError: {
      slug: null,
    },
    editorialTypes: [],
  },
  getters: {
    getLoading(state) {
      return state.loading;
    },
    getEditorialsHeaders(state) {
      return state.editorialsHeaders;
    },
    getEditorials(state) {
      return state.editorials;
    },
    getSearchedEditorials(state) {
      return state.searchedEditorials;
    },
    getEditorial(state) {
      return state.editorial;
    },
    getChangesLoading(state) {
      return state.changesLoading;
    },
    getHasMorePages(state) {
      return state.hasMorePages;
    },
    getServerError(state) {
      return state.serverError;
    },
    getEditorialTypes(state) {
      return state.editorialTypes;
    },
  },
  mutations: {
    setLoading(state, payload) {
      state.loading = payload;
    },
    setEditorials(state, payload) {
      let editorials = payload.map(el => {
        return {
          ...el,
          publish_date: el.publish_date
            ? Vue.moment(el.publish_date, "YYYY-MM-DD").format("MMM DD YYYY")
            : "—",
          disabledReorder: false,
        };
      });
      if (state.page > 1) {
        state.editorials = state.editorials.concat(editorials);
      } else {
        state.editorials = editorials;
      }
    },
    setSearchedEditorials(state, payload) {
      state.searchedEditorials = payload;
    },
    initialEditorial(state) {
      state.editorial = null;
    },
    setEditorial(state, payload) {
      state.editorial = payload;
    },
    setChangesLoading(state, payload) {
      state.changesLoading = payload;
    },
    setPage(state, payload) {
      state.page = payload;
    },
    setHasMorePages(state, payload) {
      state.hasMorePages = payload;
    },
    setError(state, payload) {
      let serverError = state.serverError;
      if (payload != null) {
        for (let i in payload) {
          serverError[i] = payload[i][0];
        }
        state.serverError = serverError;
      } else
        state.serverError = {
          slug: null,
        };
    },
    setEditorialTypes(state, payload) {
      payload[0].key = "";
      state.editorialTypes = payload;
    },
  },
  actions: {
    async apiGetEditorials({ commit }, { sort = "title", order = "asc", page = "1", status = "" }) {
      let success = false;
      commit("setLoading", true);
      commit("setHasMorePages", false);
      let query = {
        sort,
        order,
        page,
        status,
      };
      let queryStr = "";
      for (let key in query) {
        if (query[key] != "") {
          queryStr += `${key}=${query[key]}&`;
        }
      }
      await Vue.axios
        .get(`/api/admin/editorials?${queryStr}`)
        .then(res => res.data)
        .then(data => {
          success = true;
          commit("setPage", page);
          commit("setHasMorePages", data.hasMorePages);
          commit("setEditorials", data.response);
        })
        .finally(() => {
          commit("setLoading", false);
        });
      return success;
    },
    async apiGetSearchedEditorials({ commit }, { search = "", excludeIdx }) {
      let success = false,
        limit = "all";
      commit("setChangesLoading", true);

      let excludeIdxString = "";
      if (excludeIdx.length > 0) {
        excludeIdxString = excludeIdx
          .map(el => {
            return el.id;
          })
          .join(",");
      }

      await Vue.axios
        .get(
          `/api/admin/editorials?limit=${limit}&search=${search}&exclude_ids=${excludeIdxString}`
        )
        .then(res => res.data)
        .then(data => {
          success = true;
          commit("setSearchedEditorials", data.response);
        })
        .finally(() => {
          commit("setChangesLoading", false);
        });
      return success;
    },
    async apiPostEditorial({ commit }, payload) {
      let result = false;
      let data = {
        ...payload,
        is_published: payload.is_published != null ? payload.is_published : "0",
      };
      commit("setChangesLoading", true);
      await Vue.axios
        .post("/api/admin/editorial", data)
        .then(res => res.data)
        .then(data => {
          result = true;
          commit("setError", null);
        })
        .catch(e => {
          let response = e.response;
          if (response.status === 422) {
            commit("setError", response.data.detail);
          }
        })
        .finally(() => {
          commit("setChangesLoading", false);
        });
      return result;
    },
    async apiGetEditorial({ commit }, id) {
      let result = false;
      await Vue.axios
        .get(`/api/admin/editorial/${id}`)
        .then(res => res.data)
        .then(data => {
          result = true;
          commit("setEditorial", data.response);
        });
      return result;
    },
    async apiPutEditorial({ commit }, payload) {
      commit("setChangesLoading", true);
      let result = false;
      await Vue.axios
        .put(`/api/admin/editorial/${payload.id}`, payload)
        .then(res => res.data)
        .then(data => {
          result = true;
          commit("setError", null);
        })
        .catch(e => {
          let response = e.response;
          if (response.status === 422) {
            commit("setError", response.data.detail);
          }
        })
        .finally(() => {
          commit("setChangesLoading", false);
        });
      return result;
    },
    async apiDeleteEditorial({ commit }, id) {
      let result = { success: false, message: null };
      await Vue.axios
        .delete(`/api/admin/editorial/${id}`)
        .then(res => res.data)
        .then(data => {
          result.success = true;
          result.message = data.message;
        });
      return result;
    },
    async apiPutChoseOrder({ commit }, payload) {
      let list = [...payload];
      let result = false;
      commit("setChangesLoading", true);
      await Vue.axios
        .put(`/api/admin/editorials-order`, { list })
        .then(res => res.data)
        .then(data => {
          result = true;
          //commit('setPage', 1)
          //commit('setHasMorePages', data.response.hasMorePages)
          //commit('setItems', data.response.data)
          Vue.toasted.show(data.message, {
            duration: 2000,
            type: "success",
          });
        })
        .finally(() => {
          commit("setChangesLoading", false);
        });
      return result;
    },
    async apiGetEditorialTypes({ commit }) {
      await Vue.axios
        .get(`/api/admin/editorial-types`)
        .then(res => res.data)
        .then(data => {
          commit("setEditorialTypes", data.response);
        });
    },
  },
};
