import Vue from "vue";
export default {
  namespaced: true,
  state: {
    library: [],
  },
  getters: {
    getLibrary(state) {
      return state.library;
    },
  },
  mutations: {
    setLibrary(state, payload) {
      const books = "/library/";
      const editorial = "/library/publications/";
      const blog = "/library/blog/";

      const library = payload
        .filter(section => section.links.length)
        .map(section => {
          if (section.title === "Books")
            return {
              ...section,
              path: books,
              links: section.links.map(link => {
                if (
                  link.title ===
                  "The Grotta Home by Richard Meier: A Marriage of Architecture and Craft"
                ) {
                  return {
                    ...link,
                    slug: "the-grotta-home-by-richard-meier",
                  };
                }
                if (link.title === "The Grotta House by Richard Meier") {
                  return {
                    ...link,
                    slug: "the-grotta-house-by-richard-meier",
                  };
                }
                return {
                  ...link,
                  slug: "",
                };
              }),
            };
          if (section.title === "Editorial")
            return { ...section, title: "Publications", path: editorial };
          if (section.title === "Blog") return { ...section, path: blog };
        });
      state.library = library;
    },
  },
  actions: {
    async apiGetLibrary({ commit }, search) {
      await Vue.axios.get(`/api/public/library?search=${search}`).then(response => {
        commit("setLibrary", response?.data.response ?? []);
      });
    },
  },
};
