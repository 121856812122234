<template>
  <button class="button" @click.prevent="$emit('click')" :disabled="disabled">
    <slot></slot>
    <span v-if="text" class="button__text">{{ text }}</span>
    <slot name="right"></slot>
  </button>
</template>
<script>
export default {
  props: {
    text: { type: String, required: false },
    disabled: { type: Boolean, default: false },
  },
};
</script>
<style lang="scss" scoped>
.button {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid $lightGrey;
  border-radius: 7px;
  background-color: transparent;
  transition: all 0.3s ease;

  &:hover {
    background-color: $light2Grey;
  }

  svg {
    width: auto;
    height: auto;
    &::v-deep g {
      fill: $black;
    }
  }

  &[disabled] {
    pointer-events: none;
    opacity: 0.6;
  }

  &__text {
    font-weight: 600;
    margin-left: 10px;
  }
  &--auto {
    width: auto;
    height: 5.5rem;
    padding: 0 2.2rem;
  }
  &--lg {
    height: 7rem;
    padding: 0 24px;
    & .button__text {
      font-family: $fontBodoni;
      font-size: 2rem;
      line-height: 1.5;
      @media (max-width: $xxxs) {
        font-size: 1.6rem;
      }
    }
    @media (max-width: $xxs) {
      height: 5.5rem;
      padding: 0 12px;
    }
  }
  &--md {
    height: 5.5rem;
    width: 5.5rem;
  }
  &--sm {
    height: 4.4rem;
    width: 4.4rem;
  }
  &--xsm {
    height: 3.4rem;
    width: 3.4rem;
  }
  &--xs {
    border-radius: 4px;
    height: 16px;
    width: 16px;
  }
  &--uppercase {
    text-transform: uppercase;
  }
  &--white {
    background-color: $white;
    & .button__text {
      width: 100%;
      color: $black;
    }
    &:hover {
      background-color: $light2Grey;
    }
  }
  &--control {
    height: 6.8rem;
    width: 6.8rem;
    border-radius: 50%;
    border: 1px solid $white;
    background-color: $black;
    opacity: 0.8;
    z-index: 2;

    @media only screen and (max-width: $xxs) {
      height: 4.7rem;
      width: 4.7rem;
    }

    &:hover {
      background-color: $lightBlack;
    }
    svg {
      height: 1.3rem;

      @media only screen and (max-width: $xxs) {
        height: 0.9rem;
      }

      &::v-deep g {
        fill: $white;
      }
    }
  }
  &--control-white {
    border: 1px solid $white;
    background-color: $white;
    opacity: 1;
    z-index: 2;

    @media only screen and (max-width: $xxs) {
      height: 6.8rem;
      width: 6.8rem;
    }

    &:hover {
      background-color: $light2Grey;
    }
    svg {
      height: 1.4rem;

      /* @media only screen and (max-width: $xxs) {
        height: 0.9rem;
      } */

      &::v-deep g {
        fill: $lightBlack;
      }
    }
  }
  &--outline-white {
    background-color: transparent;
    border-color: $white;
    svg::v-deep g {
      fill: $white;
    }
    & .button__text {
      width: 100%;
      color: $white;
    }
    &:hover {
      background-color: rgba($color: $white, $alpha: 0.1);
      & .button__text {
        color: $white;
      }
    }
  }
  &--outline-black {
    background-color: rgba($color: $black, $alpha: 0.5);
    border-color: $white;
    svg::v-deep g {
      fill: $white;
    }
    & .button__text {
      width: 100%;
      color: $white;
    }
    &:hover {
      background-color: rgba($color: $black, $alpha: 0.7);
      & .button__text {
        color: $white;
      }
    }
  }
  &--outline-blue {
    background-color: transparent;
    border-color: $blue;
    svg::v-deep g {
      fill: $blue;
    }
    & .button__text {
      width: 100%;
      color: $blue;
    }
    &:hover {
      background-color: rgba($color: $white, $alpha: 0.1);
      & .button__text {
        color: $blue;
      }
    }
  }
  &--ellipse {
    border-radius: 34px;
  }
  &--right-icon {
    .button__text {
      margin-left: 0;
      margin-right: 5px;
    }
  }
  &--menu {
    border: none;
    border-radius: 100px;
    background-color: $white;
    padding: 7px 21px 7px 16px;
    height: 4rem;
    width: max-content;
    &:hover {
      background-color: rgba($color: $light2Grey, $alpha: 1);
    }
    .button__text {
      font-family: $fontBodoni;
      font-weight: 600;
      font-size: 1.7rem;
      line-height: 1.53;
      letter-spacing: -0.02em;
      color: $black;
    }
    @media (max-width: $xxs) {
    }
  }
}
</style>
